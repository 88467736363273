import { IModel } from "@/@types/models/model";

import { coreFields } from "./helpers/coreFields";

const AreasOfInterest: IModel = {
  title: "Areas of interest",
  apiPath: "areas-of-interest",
  forms : {
    "default": {
      fields: ["id"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "title", "wkt"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "title",
      source: "title",
      ttoken: "common.title",
      type: "text",
      readonly: true
    },
    {
      title: "code",
      source: "code",
      ttoken: "common.code",
      type: "text",
      readonly: true
    },
    {
      title: "ttoken",
      source: "ttoken",
      ttoken: "common.ttoken",
      type: "text",
      readonly: true
    },
    {
      title: "WKT",
      source: "wkt",
      ttoken: "common.wkt",
      type: "text",
      readonly: true
    },
    

  ]
}

export default AreasOfInterest;
