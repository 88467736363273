import React, { useContext, useState, useEffect, SyntheticEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";

//Custom Components
import DynamicDialog from "@/ui/Dialog/DynamicDialog";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogActionButtonClose from "@/ui/Dialog/ActionButtons/DialogActionButtonClose";
import DialogContext from "@/ui/DialogContext/DialogContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import ModelTable from "@/ui/Table/ModelTable";

import model from "@/models/imagelog";
import dataController from "@/lib/dataController";

//Types
import { ClosingDetails } from "@/@types/components/formController";
import { IDialogProps } from "@/@types/ui/DialogContext";
import { DCRecord, IDataController } from "@/@types/lib/dataController"



const ImageLogDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [records, setRecords] = useState<DCRecord[]>([]);

  const { recordId } = props;

  const dc: IDataController = new dataController(model);

  const handleClose = (evt: SyntheticEvent) => {
    close({ dataChanged: false, action: "cancel" });
  };


  const close = (result: ClosingDetails) => {
    dialogContext.hideDialog();
  };

  useEffect(() => {
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    dc.GetData(`images/${recordId}/log`).then((resp) => {
      if (resp.success) {
        if (Array.isArray(resp.data)) {
          setRecords(resp.data)
        }
        else{
          setRecords([]);
        }
      };
   });
 };

  return (
    <DynamicDialog open={true} maxWidth={"lg"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogToolbarHeading>{t("titles.image_log")}</DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <ModelTable
            title={t("titles.image_log")}
            records={records}
            dc={dc}
            allowSelection="none"
            allowFilter={false}
            allowExport={true}
            allowAdd={false}
            onRowAction={() => {}}
            allowRowAction={() => { return true;}}
          />
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
      </DialogActions>
    </DynamicDialog>
  );
};

export default ImageLogDialog;
