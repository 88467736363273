import * as React from "react";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//MUI
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

//Custom Components
import { appHeaderTheme } from "@/components/ThemeContext/ThemeProvider";
import HeaderLogo from "@/ui/Header/HeaderLogo";
import HeaderMenuButton from "@/ui/Header/HeaderMenuButton";
import ThemeSwitch from "@/ui/Header/ThemeSwitch";
import LanguageSelector from "@/ui/Header/LanguageSelector";
import ProfileMenu from "@/ui/Header/ProfileMenu";
import ModuleMenu from "@/ui/Header/ModuleMenu";
import AccessibilityMenu from "@/ui/Header/AccessibilityMenu";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";

//Types
import { IAppHeader } from "@/@types/ui/Header";

const AppHeader: FunctionComponent<IAppHeader> = (props) => {
  const { t } = useTranslation();
  const { modul, sidebarOpen, toggleSidebar } = props;
  const theme = useTheme();
  const hidden: boolean = useMediaQuery(theme.breakpoints.down("md"));

  const drawerWidth = 240;

  const toolbarStyle = {
    "& > button": {
      mx: { sm: 0, md: 1 }
    },
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    // borderBottom: "2px",
    borderStyle: "solid",
    borderColor: "primary.main"
  };

  const appBarStyle = {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: sidebarOpen ? drawerWidth : 0,
    width: sidebarOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
    transition: sidebarOpen
      ? theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      : theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        })
  };

  return (
    <MuiThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        palette: {
          ...outerTheme.palette,
          secondary: appHeaderTheme.palette.secondary
        }
      })}
    >
      <AppBar position="fixed" sx={appBarStyle} color="secondary">
        <Toolbar variant="dense" disableGutters={true} sx={toolbarStyle}>
          {!sidebarOpen ? <HeaderMenuButton toggleSidebar={toggleSidebar} /> : null}
          <ToolbarFillContent>
            {/* <HeaderLogo /> */}
            <Typography
                variant="h6"
                component="h2"
                sx={{ color: "#fff", fontSize: "1.15rem", flexGrow: 1, textAlign: "center", justifyContent: "center" }}
              >
                {t("app_title")}
                {/* <NavLink
                  to="/"
                  style={{
                    color: "#fff",
                    textDecoration: "none"
                  }}
                >
                  {t("app_title")}
                </NavLink> */}
              </Typography>
          </ToolbarFillContent>
          <LanguageSelector />
          <AccessibilityMenu />
          <ThemeSwitch />
          <ProfileMenu />
          {/* <ModuleMenu /> */}
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  );
};

export default AppHeader;
