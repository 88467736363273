import React, { useContext } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

//MUI
import { Typography, IconButton, Tooltip } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';

//MUI LAB
import { TreeItemContentProps, useTreeItem } from "@mui/lab/TreeItem";

//Components
import MapContext from "@/components/Map/MapContext";
import TimeSeriesSlider from '@/ui/Slider/TimeSeriesSlider'

//Types
import { MapContextType } from "@/@types/context/MapContext";

const LayerTreeItemCustomContent = React.forwardRef(function LayerTreeItemCustomContent(
  props: TreeItemContentProps,
  ref
) {
  const { t } = useTranslation();
  const mapContext = useContext(MapContext) as MapContextType;
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon, id } = props;
  const checked = props["aria-checked"];
  // @ts-ignore
  const { layerExtent, zoomable, timeSeriesString } = props

  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
    useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleSelection(event);
  };

  const handleZoomToLayer = () => {
    if (mapContext && mapContext.map && layerExtent) {
      const view = mapContext.map.getView();
      view.fit(layerExtent, { padding: [20, 20, 20, 220], duration: 1000});
    }
  }

  const timeseries = timeSeriesString ? timeSeriesString.split(";") : undefined;
  // test 100x
  // const timeseries = ['2022-01-01','2022-01-02','2022-01-03','2022-01-04','2022-01-05','2022-01-06','2022-01-07','2022-01-08','2022-01-09','2022-01-10','2022-01-11','2022-01-12','2022-01-13','2022-01-14','2022-01-15','2022-01-16','2022-01-17','2022-01-18','2022-01-19','2022-01-20','2022-01-21','2022-01-22','2022-01-23','2022-01-24','2022-01-25','2022-01-26','2022-01-27','2022-01-28','2022-01-29','2022-01-30','2022-01-31','2022-02-01','2022-02-02','2022-02-03','2022-02-04','2022-02-05','2022-02-06','2022-02-07','2022-02-08','2022-02-09','2022-02-10','2022-02-11','2022-02-12','2022-02-13','2022-02-14','2022-02-15','2022-02-16','2022-02-17','2022-02-18','2022-02-19','2022-02-20','2022-02-21','2022-02-22','2022-02-23','2022-02-24','2022-02-25','2022-02-26','2022-02-27','2022-02-28','2022-03-01','2022-03-02','2022-03-03','2022-03-04','2022-03-05','2022-03-06','2022-03-07','2022-03-08','2022-03-09','2022-03-10','2022-03-11','2022-03-12','2022-03-13','2022-03-14','2022-03-15','2022-03-16','2022-03-17','2022-03-18','2022-03-19','2022-03-20','2022-03-21','2022-03-22','2022-03-23','2022-03-24','2022-03-25','2022-03-26','2022-03-27','2022-03-28','2022-03-29','2022-03-30','2022-04-31','2022-04-01','2022-04-02','2022-04-03','2022-04-04','2022-04-05','2022-04-06','2022-04-07','2022-04-08','2022-04-09','2022-04-10']
  const hasTimeSeriesSlider = timeseries && timeseries.length > 0;
  const ticks = timeseries ? timeseries.length - 1 : 0;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <Tooltip title={t("map:tooltips.zoom_to_extent") as string}>
        <IconButton onClick={handleZoomToLayer} color="primary" disabled={!layerExtent} edge='start' sx={ !zoomable ?{visibility: 'hidden'} : null}>
          <LocationOnIcon />
        </IconButton>
      </Tooltip>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
      { hasTimeSeriesSlider ? <TimeSeriesSlider 
          id={id}
          ticks={ticks}
          checked={checked}
          timeseries={timeseries}
          syncGroup={true}
        /> : null}
    </div>
  );
});

export default LayerTreeItemCustomContent;
