import React from "react";

//MUI
import Avatar from "@mui/material/Avatar";
import { IMapCardAvatar } from "@/@types/ui/MapCard";

function MapCardAvatar(props: IMapCardAvatar) {
  const { icon, ariaLabel } = props;

  const { className } = icon.props;

  return (
    <Avatar
      aria-label={ariaLabel}
      sx={{
        height: "30px",
        width: "30px",
        ".MuiAvatar-root": {
          backgroundColor: "common.white"
        }
      }}
    >
      <icon.type className={className ? className : ""} sx={{ color: "primary.main" }} />
    </Avatar>
  );
}

export default MapCardAvatar;
