import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

// d.ts
import { DummyRecordType } from "@/@types/views/Administration/user";
import { DCRecord, IDataController } from "@/@types/lib/dataController"

// Material UI Core
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Toolbar from "@mui/material/Toolbar";

// Material UI Icons
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

// Custom Components
import FormButton from "@/ui/Form/FormButton";
import ModelTable from "@/ui/Table/ModelTable";
import TableButtonEdit from "@/ui/Table/ActionButtons/TableButtonEdit";
import TableButtonDelete from "@/ui/Table/ActionButtons/TableButtonDelete";
import DialogContext from "@/ui/DialogContext/DialogContext";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import UserDialog from "./UserDialog";

import model from "@/models/user";
import dataController from "@/lib/dataController";
import { DialogContextType } from "@/@types/ui/DialogContext";
import TableHeaderButtonFilter from "@/ui/Table/HeaderButtons/TableHeaderButtonFilter";
import { FilterAltOff } from "@mui/icons-material";


// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

function UsersTable() {
  const dialogContext = useContext<DialogContextType>(DialogContext);
  const { t } = useTranslation();

  const [records, setRecords] = useState<DCRecord[]>([]);

  const dc: IDataController = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleAdd = () => {
    dialogContext.showDialog(UserDialog, {
      dc: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose
    });
  };

  const handleEdit = (evt: React.SyntheticEvent, sel: string[]) => {
    const selId: number = parseInt(sel[0], 10);
    const record: DCRecord = records[selId];
    dialogContext.showDialog(UserDialog, {
      dc: dc,
      mode: "update",
      form: "update",
      recordId: record.id,
      onClose: handleDialogClose
    });
  };

  const handleDelete = (evt: React.SyntheticEvent, sel: string[]) => {

  }

  const handleDialogClose = (result: { dataChanged: boolean; action: string }) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
     dc.GetData().then((resp) => {
       if (resp.success) {
         if (Array.isArray(resp.data)) {
           setRecords(resp.data)
         }
         else{
           setRecords([]);
         }
       };
    });
  };

  return (
    <Card>
      <CardContent>
        <ModelTable
          title={t("titles.users")}
          records={records}
          dc={dc}
          allowSelection="many"
          allowFilter={true}
          allowExport={true}
          allowAdd={true}
          addLabel={t("buttons.new_m")}
          handleAdd={handleAdd}
          onRowAction={() => {}}
          allowRowAction={() => { return true;}}
        >
          <TableButtonDelete variant="outlined" onClick={handleDelete} />
          <TableButtonEdit variant="contained" onClick={handleEdit} />
        </ModelTable>
      </CardContent>
    </Card>
  );
}

export default UsersTable;
