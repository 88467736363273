import { FunctionComponent, useContext, useEffect } from "react";
import clsx from "clsx";

//OpenLayers ext
import Button from "ol-ext/control/Button";

//Custom components
import BarControlContext from "./Bar/BarControlContext";
import MapContext from "@/components/Map/MapContext";
import makeMUIControlStyle from "../makeMUIControlStyle";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { BarControlContextType } from "@/@types/components/Map/Controls/OpenLayersExt/Bar";
import { IButtonControl } from "@/@types/components/Map/Controls/OpenLayersExt";

const ButtonControl: FunctionComponent<IButtonControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;
  const parentBarContext = useContext(BarControlContext) as BarControlContextType;
  const classes = makeMUIControlStyle();

  const overwrittenProps = {
    ...props,
    className: clsx(props.className, classes.control)
  }

  const options = {
    className: classes.control,
    title: undefined,
    name: undefined,
    html: undefined,
    handleClick: undefined,
    target: undefined
  };

  const events = {};

  useEffect(() => {
    let allOptions = Object.assign(options, overwrittenProps);
    let definedOptions = getDefinedOptions(allOptions);

    let control = new Button(definedOptions);

    if(props.id) {
      control.set("id", props.id)
    }

    if (parentBarContext && parentBarContext.exists) {
      parentBarContext.childControls.push(control);

    } else if (context.map) {
      const mapControl = findControl(context.map, props.id, Button);
      if (mapControl) {
        context.map.removeControl(mapControl);
        console.log('control removed', Button);
      }
      context.map.addControl(control);
      console.log('control added', Button);
    } else {
      context.initOptions.controls.push(control);
    }

    let olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (context.map) {
        const mapControl = findControl(context.map, props.id, Button);
        if (mapControl) {
          context.map.removeControl(mapControl);
        }
      }
    }

  }, [
    props.className,
    props.name,
    props.handleClick,
    classes.control
  ])

  useEffect(() => {
    if (context.map) {
      const mapControl = findControl(context.map, props.id, Button);
      if (mapControl) {
        //@ts-ignore
        mapControl.setTitle(props.title);
      }
    }
  }, [props.title])

  useEffect(() => {
    if (context.map) {
      const mapControl = findControl(context.map, props.id, Button);
      if (mapControl) {
        //@ts-ignore
        mapControl.setHtml(props.html);
      }
    }
  }, [props.html])

  return null;
};

export default ButtonControl;

