import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material"

function LineColumn (props: any) {
  const { t } = useTranslation();
  const { series, title, yLabel, labels, avg } = props
  const theme = useTheme()

  const minVal = Math.min(...series[0].data)
  const maxVal = Math.max(...series[0].data)

  const options = {
    title: {
      text: title,
      align: 'left',
      offsetX: 13,
      style: {
        color: theme.palette.text.primary
      }
    },
    dataLabels: {
      enabled: false,
    },
    labels: labels,
    xaxis: {
      type: 'category',
      tickPlacement: 'between',
      labels: {
        rotateAlways: true,
        rotate: -45,
        style: {
          colors: theme.palette.text.primary
        }
      }
    },
    yaxis: {
      min: Math.floor(minVal),
      max: Math.ceil(maxVal),
      tickAmount: 4,
      title: {
        text: yLabel,
        offsetX: -5,
        style: {
          color: theme.palette.text.primary
        }
      },
      labels: {
        style: {
          colors: theme.palette.text.primary
        },
        formatter: (val: number) => {return val.toFixed(2)},
      }
    },
    annotations: {
      yaxis: avg ? [
        {
          y: avg,
          borderWidth: 2,
          strokeDashArray: 6,
          borderColor: '#00E396',
          label: {
            text: `${t("map:analysis.average_value")}: ${avg}`,
            textAnchor: 'start',
            position: 'left',
            borderColor: '#00E396',
            style: {
              color: '#000',
              background: '#00E396'
            }
          }
        }
      ] : []
    },
    plotOptions: {
      bar: {
        columnWidth: '40%'
      }
    }
  }

  return (
    <Chart
      type="line"
      // @ts-ignore
      options={options}
      series={series}
      height="300px"
    />
  );
}


export default LineColumn