import React, { Component, Fragment, useState, useEffect, useContext, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// d.ts
import { DrawingType, CoordinateSystemType, BoolSettings, ViewOptionsType, IMapView } from "@/@types/common/index";
import {
  IMapProps,
  IMapState,
  CardPosition,
  CardOverlayType,
  ICardOverlay,
  ICardOverlayData
} from "@/@types/views/GIS/map";

// OpenLayers
import Geometry from "ol/geom/Geometry";
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import { get as OlGetProjection } from "ol/proj";
import Projection from "ol/proj/Projection";
import OlLayerGroup from "ol/layer/Group";
import OlLayerVector from "ol/layer/Vector";
import OlSourceVector from "ol/source/Vector";
import OlFormatWKT from "ol/format/WKT";
import Map from "@/components/Map/Map";
import MapEvent from "ol/MapEvent";
import { DrawEvent } from "ol/interaction/Draw";
import BaseEvent from "ol/events/Event";
import OlMapBrowserEvent from "ol/MapBrowserEvent";
import OlBaseLayer from "ol/layer/Base";
import OlFeature from "ol/Feature";
import OlCollection from "ol/Collection";
import OlPolygon from "ol/geom/Polygon"
import OlPoint from "ol/geom/Point";
import { Extent as OlExtent } from "ol/extent";

// Custom
import {
  Controls,
  AttributionControl,
  CoordZoomStatusControl,
  DefaultControls,
  FullScreenControl,
  MeasureControls,
  OverviewMapControl,
  RotateControl,
  ScaleLineControl,
  ScaleRatioControl,
  StatusControl,
  ZoomControl,
  ZoomToExtentControl,
  GeoLocateControl,
  ZoomSliderControl,
  CenterMarkerControl,
  LayerSwitcher,
  LayerTree,
  ViewHistoryControl
} from "@/components/Map/Controls";

import {
  SidebarContent,
  SidebarControl,
  SidebarHeading,
  SidebarPane,
  SidebarTabs,
  SidebarTabList,
  SidebarTabListItem
} from "@/components/Map/Controls/Sidebar";

import { InfoPane, LayersPane, MeasuresPane, MapLegend, AnalysisPane, NewLayersPane } from "@/components/MapSidebarPanes";
import { Layers, TileLayer, VectorLayer, GroupLayer } from "@/components/Map/Layers";
import { Overlays, PopupOverlay, MeasureTooltipOverlay } from "@/components/Map/Overlays";
import { Interactions, DefaultInteractions, DrawInteraction } from "@/components/Map/Interactions";
import { drawStyle, measurementsStyle, measuringStyle, selectedStyle, aoiStyle, inSituStyle } from "@/components/Map/mapStyles";
import UserContext from "@/components/UserContext/UserContext";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import { DCRecord } from "@/@types/lib/dataController";
import { flattenLayers, getCallDirectLayers, getCallGroupedLayers, getOnlyGroupLayers } from "@/lib/olHelpers";
import { GFIFeatureType } from "@/@types/services/gsService";

import GeoBaseLayerSwitcher from "@/components/Map/Controls/GEO/GeoBaseLayerSwticher";
import GeoAPILayers from "@/components/Map/Layers/GEO/GeoAPILayers";
import GSInfoCard from "./GSInfoCard";
import modelAOI from "@/models/areasOfInterest";
import dataController from "@/lib/dataController";

//Services
import mapService from "@/services/mapService";
import gsService from "@/services/gsService";
import { authService } from "@/services/authService";

//Types
import { LoaderContextType } from "@/@types/context/LoaderContext";

function MainMap(props: IMapProps) {
  const userContext = useContext(UserContext);
  const loaderContext = useContext<LoaderContextType>(LoaderContext)
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const [mapInitialized, setMapInitialized] = useState(false);

  const [viewOptions, setViewOptions] = useState<ViewOptionsType>({
    center: userContext?.mapSettings ? userContext.mapSettings.initial_view_center : [1731757, 5581737],
    zoom: userContext?.mapSettings ? userContext.mapSettings.initial_view_zoom : 8,
    extent: userContext?.mapSettings ? userContext.mapSettings.max_extent : undefined,
    //projection: wgs84PM,
    minZoom: 8,
    maxZoom: 20,
    constrainResolution: true
  });

  const [drawingSource, setDrawingSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [measurementsSource, setMeasurementsSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [boundariesSource, setBoundariesSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));

  const [chosenCoordinateSystem, setChosenCoordinateSystem] = useState<CoordinateSystemType>("WGS84");
  const [layersCollection, setLayersCollection] = useState<OlCollection<OlBaseLayer> | undefined>(undefined);
  const [forceRefreshCounter, setForceRefreshCounter] = useState(0);
  const [drawType, setDrawType] = useState<DrawingType | undefined>(undefined);
  const [isDrawingAnalysis, setIsDrawingAnalysis] = useState(false);
  const [feature, setFeature] = useState<OlFeature<Geometry> | undefined>(undefined);
  const [measuringFeature, setMeasuringFeature] = useState<OlFeature<Geometry> | undefined>(undefined);
  const [cardOverlay, setCardOverlay] = useState<ICardOverlay | undefined>(undefined);
  const [defaultExtent, setDefaultExtent] = useState(
    userContext?.mapSettings?.default_extent
      ? userContext.mapSettings.default_extent
      : [1437016, 5271097, 2206278, 5860579]
  );

  const [aoiRecords, setAOIrecords] = useState<DCRecord[]>([]);

  const [zoomToExtent, setZoomToExtent] = useState<OlExtent | undefined>();

  const [aoiFeature, setAoiFeature] = useState<OlFeature<OlPolygon> | null>(null);
  const [clickCount, setClickCount] = useState(0);

  const zoomToDefaultExtentElementRef = useRef<HTMLLIElement>(null);
  const fullScreenElementRef = useRef<HTMLLIElement>(null);
  const geoLocateElementRef = useRef<HTMLLIElement>(null);
  const centerMarkerElementRef = useRef<HTMLLIElement>(null);

  //define proj
  proj4.defs(
    "EPSG:3765",
    "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
  );
  proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
  proj4.defs(
    "EPSG:31276",
    "+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs"
  );
  proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
  );
  OlRegister(proj4);

  const htrs96 = OlGetProjection("EPSG:3765");
  const wgs84 = OlGetProjection("EPSG:4326");
  const wgs84PM = OlGetProjection("EPSG:3857");

  // defaultViewCenter = [1731757, 5581737];
  // initialDefaultExtent = [1688674,5501166,1688774,5501266];
  const defaultViewCenter = userContext?.mapSettings?.initial_view_center;
  const initialDefaultExtent = userContext?.mapSettings?.default_extent;

  const dcAOI = new dataController(modelAOI);

  useEffect(() => {
    getLayers();
    getBoundaries();
  }, []);

  const getLayers = () => {
    mapService.getLayers().then((coll) => {
      setLayersCollection(coll);
      setMapInitialized(true);
    });
  };

  const getBoundaries = () => {
    dcAOI.GetData().then((resp) => {
      if (resp.success) {
        if (Array.isArray(resp.data) && resp.data.length > 0) {
          setAOIrecords(resp.data as DCRecord[]);
          const wktFormatter = new OlFormatWKT();

          const features = resp.data.map(x => {
            const f = wktFormatter.readFeature(x.wkt, {
              dataProjection: "EPSG:3857",
              featureProjection: "EPSG:3857"
            })
            f.set("code", x.code);
            return f;
          });
          const src = new OlSourceVector({ features: features });
          setBoundariesSource(src);

          const extent = src.getExtent();
          setZoomToExtent(extent);
          setDefaultExtent(extent);
        }
        else {
          setBoundariesSource(new OlSourceVector({}));
        }
      }
    })

  }

  // getDefaultData() {
  //   mapService.getDefaultData().then((data) => {
  //     if (data) {
  //       const viewData = Array.isArray(data) ? Object.assign({}, data[0]) : Object.assign({}, data);
  //       this.setState((prevState) => {
  //         return {
  //           ...prevState,
  //           viewOptions: {
  //             ...prevState.viewOptions,
  //             zoom: viewData.initial_view_zoom,
  //             center: viewData.initial_view_center
  //           },
  //           zoomToExtent: viewData.default_extent
  //         };
  //       });
  //     }
  //   });
  // }

  // const setViewCenter = (view: IMapView) => {
  //   setViewOptions((prevState) => {
  //     return {
  //       ...prevState,
  //       center: view.center,
  //       zoom: view.zoom
  //     };
  //   });
  // };

  const changeCoordinateSystemDisplay = (type: CoordinateSystemType) => {
    setChosenCoordinateSystem(type);
  };

  const handleClick = useCallback(
    (evt: OlMapBrowserEvent<any>) => {
      let hit = false;
      if (userContext && userContext.expireTime) {
        checkToken(userContext.expireTime)
      }

      const zoomLevel = evt.map.getView().getZoom()

      if (drawType || isDrawingAnalysis) {
        return;
      }

      const feature = evt.map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        },
        {
          layerFilter: (layer) => {
            const layerId = layer.get("id");
            return layerId !== null && layerId !== undefined && layerId.startsWith("boundaries");
          }
        }
      );

      if (feature) {

        const isAOI = feature.getGeometry() instanceof OlPolygon;

        if (isAOI) {
          if (zoomLevel && zoomLevel < 11) {
            const id = feature.getId();
            hit = true;

            const extent = feature.getGeometry()?.getExtent()

            if (extent) {
              evt.map.getView().fit(extent, { duration: 1000 })
            }
          }
        } 
        // else if (isInSitu) {
        //   hit = true;
        //   const data = {
        //     position: evt.coordinate as CardPosition,
        //     feature: undefined,
        //     type: "generic" as CardOverlayType,
        //     record: {
        //       chl: feature.get("chl") + " mg/m³",
        //       timestamp: feature.get("timestamp")
        //     }
        //   } as ICardOverlayData;
        //   showCardOverlay("gsinfo", data, null);
        // }
      } else {
        closeOverlays();
      }

      if (!hit) {
          const item = localStorage.getItem("maplayers");
          const visibility = item ? (JSON.parse(item) as BoolSettings) : ({} as BoolSettings);
          const hiddenLayers = visibility ? Object.keys(visibility).filter((key) => visibility[key] === false) : [];
          const allLayers = layersCollection
            ? flattenLayers(layersCollection.getArray()).filter((x) => !(x instanceof OlLayerGroup))
            : [];
          const visibleLayers = allLayers.filter((x) => hiddenLayers.indexOf(x.get("id")) === -1);
          // const GSLayerNames = visibleLayers.filter((x) => x.get("query") === true).map((x) => x.get("layer"));
          const GSLayerNames = visibleLayers.filter((x) => x.get("query") === true && x.get("id") === "app-sb-insitu").map((x) => x.get("layer"));
          
          

          // loaderContext.toggleLoading(true);
          gsService.getFeatureInfo(evt.map, evt.pixel, GSLayerNames).then((resp) => {
            if (resp && Object.keys(resp).length != 0) {
              const layerKeys = Object.keys(resp);
              const features = resp[layerKeys[0]];
              const firstLayer = resp[layerKeys[0]];
              const firstFeature = firstLayer[0];
              const featureType = "generic";
              const data = {
                position: evt.coordinate as CardPosition,
                feature: firstFeature,
                type: featureType as CardOverlayType,
                record: undefined
              } as ICardOverlayData;
              //TODO: depending on ID, define data type, send different id instead of "gsinfo"
              showCardOverlay("gsinfo", data, null);
              // const showCard = () => showCardOverlay("gsinfo", data, null);
              // closeOverlays(showCard);
            } else {
              closeOverlays();
            }
          })
          .finally(() => {
            // loaderContext.toggleLoading(false);
          });
      }
    },
    [drawType, isDrawingAnalysis, layersCollection, clickCount]
  );

  const showCardOverlay = (type: CardOverlayType, data: ICardOverlayData, ft: OlFeature<any> | null) => {
    //TODO: refactor
    feature ? feature.setProperties({ selected: false }) : null;

    if (ft) {
      ft.setProperties({ selected: true });
    }

    if (type === "gsinfo") {
      setCardOverlay({
        type: type,
        data: data
      });
      if (ft) {
        setFeature(ft);
      } else {
        setFeature(undefined);
      }
    }
  };

  const closeOverlays = (callback = () => { }) => {
    setCardOverlay(undefined);
    // this.setState(
    //   {
    //     cardOverlay: undefined
    //   },
    //   typeof callback === "function" ? () => callback() : () => {}
    // );
  };

  // const handleViewChangeCenter = (evt: MapEvent) => {
  //   if (evt && evt.map) {
  //     const newView = {
  //       center: evt.map.getView().getCenter(),
  //       zoom: evt.map.getView().getZoom()
  //     };
  //     setViewOptions(Object.assign(viewOptions, newView));
  //   }
  // };

  const handleSidebarPaneChange = useCallback(
    (/*id*/) => {
      if (drawType) {
        setDrawType(undefined);
      }
    },
    [drawType]
  );

  const changeDrawType = useCallback(
    (type: DrawingType) => {
      if (drawType !== type) {
        setDrawType(type);
        setMeasuringFeature(undefined);
      } else {
        setDrawType(undefined);
        setMeasuringFeature(undefined);
      }
    },
    [drawType]
  );

  const handleDrawMeasureStart = (evt: DrawEvent) => {
    if (drawingSource.getFeatures().length > 0) {
      setDrawingSource(new OlSourceVector({}));
    }

    setMeasuringFeature(evt.feature);
  };

  const handleDrawMeasureEnd = (evt: DrawEvent) => {
    setDrawingSource(new OlSourceVector({}));
    setMeasurementsSource(
      (prevState) =>
        new OlSourceVector({
          features: [...prevState.getFeatures(), evt.feature]
        })
    );

    setForceRefreshCounter((prevState) => (prevState ? prevState + 1 : 1));
  };

  const handleDrawMeasureChange = (evt: BaseEvent) => {
    // console.log("handleDrawChange", evt);
  };

  const handleEraseMeasurements = () => {
    setMeasurementsSource(new OlSourceVector({}));
  };

  const handleLayerVisibilityChange = () => {
    setForceRefreshCounter((prevState) => prevState + 1);
  };

  const gsOverlay = cardOverlay && cardOverlay.type === "gsinfo" ? cardOverlay : undefined;

  // console.log("render map");

  const WKT = "MULTIPOLYGON (((569494.624670257 4742171.85691785,570337.627042465 4742442.86635835,570337.627042465 4742442.86635835,571397.44347635 4742260.48785207,573757.214751861 4741604.94419877,576819.853612933 4740242.36927488,578184.134384501 4738787.03458547,576865.505360361 4734762.83256921,575777.725806011 4733371.61311226,572497.220078677 4733981.46032587,567526.466750002 4736114.47382012,565390.653790757 4737869.58809044,565613.824700893 4740073.78370677,567183.796944418 4740985.91003597,568105.33075351 4741072.01427339,569494.624670257 4742171.85691785)))"

  const handleLoadArea = () => {

    const wktFormatter = new OlFormatWKT();
    const feat = wktFormatter.readFeature(WKT, {
      dataProjection: "EPSG:3765",
      featureProjection: "EPSG:3857"
    })
    feat.setStyle(aoiStyle)
    setAoiFeature(feat);

    const source = new OlSourceVector<Geometry>({
      features: [feat]
    })
    const layer = new OlLayerVector({
      source: source
    })
    layer.set('title', 'AOI')
    layer.set('id', 'AOI')
    layer.set('z_index', '30')
    layer.set("default_visible", true);

    setLayersCollection(prevState => {
      const newArray = prevState?.getArray().concat([layer]);
      return new OlCollection(newArray);
    })
  }

  const handleClearArea = () => {
    setClickCount(0);
    setLayersCollection(prevState => {
      const newArray = prevState?.getArray().filter(x => x.get("id") !== "AOI");
      return new OlCollection(newArray);
    })
  }

  const handleToggleDrawAnalysis = (isActive: boolean) => {
    setIsDrawingAnalysis(isActive);
  }

  function checkToken(time: any) {
    const isExpired = time < moment()
    if (isExpired) {
      authService.logout()
      snackbarContext.showNotification("messages.token_expired", "warning");
      navigate('/login')
    }
  }

  return (
    <Fragment>
      {userContext && layersCollection ? (
        <Map
          height="800px"
          view={viewOptions}
          onClick={handleClick}
          className="sidebar-map"
          id="main-map"
          initialized={mapInitialized}
          moveTolerance={10}
          maxTilesLoading={10}
          zoomToExtent={zoomToExtent}
        >
          <Controls>
            {/* <AttributionControl/> */}
            {/* <OverviewMapControl /> */}

            <CoordZoomStatusControl chosenCoordinateSystem={chosenCoordinateSystem} />
            <StatusControl changeCoordinateSystem={changeCoordinateSystemDisplay} />
            <ZoomControl zoomInTipLabel={t("map:controls.zoom_in")} zoomOutTipLabel={t("map:controls.zoom_out")} />
            <ZoomSliderControl />
            {/*<ScaleControl className="ol-control ol-scale-ratio ol-sidebar-sticky" ppi={96} />*/}
            <ScaleRatioControl ppi={96} />
            <ScaleLineControl />
            {/* <FullScreenControl id="full-screen-ctrl" tipLabel={t("map:controls.full_screen")} /> */}
            <RotateControl autoHide={false} tipLabel={t("map:controls.reset_north")} />
            <ViewHistoryControl />
            {fullScreenElementRef?.current && mapInitialized ? (
              <FullScreenControl
                id="full-screen"
                tipLabel={t("map:controls.full_screen")}
                target={fullScreenElementRef?.current}
                className="ol-sidebar-control"
              />
            ) : null}

            {zoomToDefaultExtentElementRef?.current && mapInitialized ? (
              <ZoomToExtentControl
                id="zoom-extent-default"
                target={zoomToDefaultExtentElementRef.current}
                extent={defaultExtent}
                tipLabel={t("map:controls.zoom_to_extent")}
                className="ol-sidebar-control"
              />
            ) : null}
            {geoLocateElementRef?.current && mapInitialized ? (
              <GeoLocateControl
                id="geo-locate"
                tooltip={t("map:controls.geolocate")}
                target={geoLocateElementRef.current}
                className="ol-sidebar-control"
              />
            ) : null}
            {centerMarkerElementRef?.current && mapInitialized ? (
              <CenterMarkerControl
                id="center-marker"
                tooltip={t("map:controls.center_marker")}
                target={centerMarkerElementRef.current}
                className="ol-sidebar-control"
              />
            ) : null}
            <GeoBaseLayerSwitcher allowNoLayer={true} />
            <SidebarControl initialOpenId="layers" onTabChange={handleSidebarPaneChange}>
              <SidebarTabs>
                <SidebarTabList>
                  <SidebarTabListItem
                    id="info"
                    title={t("map:sidebar.info")}
                    icon={<i className="fas fa-info-circle"></i>}
                  />
                  <SidebarTabListItem
                    id="layers"
                    title={t("map:sidebar.layers")}
                    icon={<i className="fas fa-layer-group"></i>}
                  />
                  <SidebarTabListItem
                    id="analysis"
                    title={t("map:sidebar.analysis")}
                    icon={<i className="fas fa-chart-area"></i>}
                  />
                  <SidebarTabListItem
                    id="measures"
                    title={t("map:sidebar.measures")}
                    icon={<i className="fas fa-pencil-ruler"></i>}
                  />
                  <li ref={zoomToDefaultExtentElementRef}></li>
                  <li ref={fullScreenElementRef}></li>
                  {/* <li ref={geoLocateElementRef}></li> */}
                  {/* <li id="center-marker-container" ref={centerMarkerElementRef}></li> */}

                  {/* <SidebarTabListItem
                    id="legend"
                    title={t("map:sidebar.legend")}
                    icon={<i className="fas fa-atlas"></i>}
                  /> */}
                </SidebarTabList>
              </SidebarTabs>
              <SidebarContent>
                <SidebarPane id="info">
                  <SidebarHeading title={t("map:sidebar.info")} />
                  <InfoPane />
                </SidebarPane>
                <SidebarPane id="layers">
                  <SidebarHeading title={t("map:sidebar.layers")} />
                  <NewLayersPane
                    layersCollection={layersCollection}
                    onLayerVisibilityChange={handleLayerVisibilityChange}
                  />
                  {/* <LayerSwitcher ready={layersCollection ? true : false} /> */}
                  {/* <LayersPane layersGroup={true} /> */}
                </SidebarPane>
                <SidebarPane id='analysis'>
                  <SidebarHeading title={t("map:sidebar.analysis")} />
                  <AnalysisPane
                    layers={layersCollection}
                    onLoaded={handleLoadArea}
                    onClear={handleClearArea}
                    onToggleDraw={handleToggleDrawAnalysis}
                    aoiFeature={aoiFeature ? aoiFeature : undefined}
                    aoiRecords={aoiRecords}
                    showPixelGraph={clickCount} />
                </SidebarPane>
                <SidebarPane id="measures">
                  <SidebarHeading title={t("map:sidebar.measures")} />
                  <MeasuresPane
                    // toggleDraw={handleToggleDrawMeasure} TODO: implement method handleToggleDrawMeasure in this component
                    changeDrawType={changeDrawType}
                    handleEraseMeasurements={handleEraseMeasurements}
                    drawType={drawType}
                  />
                </SidebarPane>

                {/* <SidebarPane id="legend">
                  <SidebarHeading
                    title={t("map:sidebar.legend")}/>
                  <MapLegend/>
                </SidebarPane> */}
              </SidebarContent>
            </SidebarControl>
          </Controls>
          <Layers>
            {/* <Layers id="osm" osm={true} /> */}
            {/* <GeoportalBaseLayer id="osm" zIndex={1} /> */}
            {layersCollection ? (
              <Layers>
                <GeoAPILayers layersCollection={layersCollection} />
                <VectorLayer id="measurements" source={measurementsSource} style={measurementsStyle} zIndex={900} />
                <VectorLayer id="boundaries" title='Obuhvati' source={boundariesSource} zIndex={2} />
              </Layers>
            ) : null}
          </Layers>
          <Overlays>
            <PopupOverlay
              id="feature-overlay"
              position={gsOverlay ? gsOverlay.data.position : undefined}
              autoPan={true}
              onClose={() => { }}
            >
              {gsOverlay ? <GSInfoCard featureData={gsOverlay.data} onClose={closeOverlays} /> : null}
            </PopupOverlay>
            <MeasureTooltipOverlay id="measure-overlay" feature={measuringFeature} position={undefined} />
          </Overlays>
          <Interactions>
            <DefaultInteractions />
            {drawType ? (
              <DrawInteraction
                source={drawingSource}
                type={drawType}
                style={measuringStyle}
                onChange={handleDrawMeasureChange}
                onDrawstart={handleDrawMeasureStart}
                onDrawend={handleDrawMeasureEnd}
              />
            ) : null}
          </Interactions>
        </Map>
      ) : null}
    </Fragment>
  );
}

export default MainMap;
