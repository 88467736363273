import OlMap from "ol/Map";
import OlPoint from "ol/geom/Point";
import { IHeaders } from "@/@types/lib/api/api";
import { IGSService, GFIResponseType, GFILayerFeatureType, GFIFeatureType} from "@/@types/services/gsService";
import { authHeader } from "@/lib/api/authHeader";

const gsService: IGSService = {
  getFeatureInfo
};

const INFO_FORMAT_JSON = "application/json";
const INFO_FORMAT_HTML = "text/html"
const INFO_FORMAT_TEXT = "text/plain";


function getFeatureInfo(map: OlMap, point: number[], gsLayers: Array<string>, time?: string): Promise<GFIResponseType | null> {
  let headers: IHeaders = authHeader();
  headers['Content-Type'] = 'text/plain';
  headers['Accept'] = 'text/html'

  const mapSize = map.getSize();
  const extent = map.getView().calculateExtent(mapSize);

  const requestOptions = {
    method: 'GET',
    headers: headers
  }

  //TODO: need to define in which layer are we seraching for.
  //const query_layers = ["DVK:data"];
  const query_layers = gsLayers;

  let layers = query_layers.join(',');

  const gsUrl = process.env.REACT_APP_GEOSERVERPATH;
  const url = gsUrl
    + "?SERVICE=WMS"
    + "&VERSION=1.1.1"
    + "&REQUEST=GetFeatureInfo"

    + "&LAYERS=" + layers
    + "&STYLES="
    + "&SRS=EPSG:3857"
    + "&BBOX=" + extent
    + "&WIDTH=" + (Array.isArray(mapSize) ? mapSize[0] : "")
    + "&HEIGHT=" + (Array.isArray(mapSize) ? mapSize[1] : "")
    + "&QUERY_LAYERS=" + layers
    + "&FORMAT=image/png"
    + "&INFO_FORMAT=" + INFO_FORMAT_JSON
    + "&FEATURE_COUNT=100"
    + "&X=" + point[0].toFixed(0)
    + "&Y=" + point[1].toFixed(0)
    + "&BUFFER=10"
    + (time !== undefined ? ("&TIME=" + time) : "")
    ;

    return fetch(url, requestOptions)
    .then( response => {
      return response.text().then( text => {
        if(!response.ok) {
          console.log(response);
          return null;
        } else {
          const js = JSON.parse(text);
          const data = parseFeatures(js.features as GFIFeatureType[]);
          return data;
        }
        // const data = text && JSON.parse(text)
        // if (!response.ok) {
        //   const error = (data && data.message) || response.statusText;
        //   return Promise.reject(error);
        // }

        // return data;
      })

    })
    .catch( function(error) {
      return null;
    });
}

function parseFeatures(features: GFIFeatureType[]) {
  const layerFeatures: Array<GFILayerFeatureType> = features.map((feature) => {
    const ind = feature.id.indexOf('.');
    const layerId = feature.id.slice(0, ind);
    const properId = feature.id.slice(ind + 1);
    for(var propName in feature.properties) {
      if(feature.properties[propName] === null) {
        delete feature.properties[propName];
      }
    }
    return {
      layerId: layerId,
      properId: properId,
      properties: feature.properties,
      geometry_name: feature.geometry_name,
      geometry: feature.geometry
    } as GFILayerFeatureType
  });
  return sortInLayers(layerFeatures);
}

function sortInLayers(object: Array<GFILayerFeatureType>) {
  var resp: GFIResponseType = {};
  for(var feature of object) {
    const newFeat: GFIFeatureType = {id: feature.properId, properties: feature.properties, geometry: feature.geometry, geometry_name: feature.geometry_name};

    if(resp[feature.layerId]) {
      resp[feature.layerId].push(newFeat);
    } else {
      resp[feature.layerId] = [newFeat];
    }
  }
  return resp;
}

export default gsService;