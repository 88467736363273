import api from "@/lib/api";

export type ConfirmationData = {

}

interface IImagesService {
  activate: (image_id: number) => Promise<ConfirmationData>,
  deactivate: (image_id: number) => Promise<ConfirmationData>
}

const imagesService: IImagesService = {
  activate,
  deactivate
};

const images_url = "images";

function activate(image_id: number): Promise<ConfirmationData> {
  const apiInstance = new api();
  const url = `${images_url}/${image_id}/activate`

  const data = {}

  return apiInstance.Call(url, "patch", data);
}

function deactivate(image_id: number): Promise<ConfirmationData> {
  const apiInstance = new api();
  const url = `${images_url}/${image_id}/deactivate`

  const data = {}

  return apiInstance.Call(url, "patch", data);
}

export default imagesService;
