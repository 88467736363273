import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";

//MUI Core Components
import { Typography, Divider, Button, Stack, styled } from "@mui/material";

//MUI Icons
import ReplayIcon from "@mui/icons-material/Replay";
import BarChartIcon from "@mui/icons-material/BarChart";

// OpenLayers
import OlSourceVector from "ol/source/Vector";
import OlLayerVector from "ol/layer/Vector";
import OlLayerTile from "ol/layer/Tile";
import OlFeature from "ol/Feature";
import Geometry from "ol/geom/Geometry";
import OlPolygon from "ol/geom/Polygon";
import { getCenter as OlGetCenter } from "ol/extent";
import Draw from "ol/interaction/Draw";
import { DrawEvent } from "ol/interaction/Draw";
import OlMap from "ol/Map";
import OlCollection from "ol/Collection";
import OlBaseLayer from "ol/layer/Base";
import { getArea } from "ol/sphere";

//Custom Components
import LineColumn from "@/ui/Charts/LineColumn";
import MapContext from "@/components/Map/MapContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import { analyzingStyle, analysisStyle } from "@/components/Map/mapStyles";
import RadioControl from "@/controls/RadioControl";
import PickerControl from "@/controls/PickerControl";
import TimeSeriesRangeSlider from "@/ui/Slider/TimeSeriesRangeSlider";

//Services
import analysisService from "@/services/analysisService";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { flattenLayers } from "@/lib/olHelpers";
import { IFieldPickerItems } from "@/@types/models/model";
import { PickerItem } from "@/@types/controls/controls";
import { DCRecord } from "@/@types/lib/dataController";
import { LoaderContextType } from "@/@types/context/LoaderContext";

type AnalysisPaneProps = {
  layers: OlCollection<OlBaseLayer>;
  onLoaded: () => void;
  onClear: () => void;
  aoiRecords: DCRecord[];
  aoiFeature?: OlFeature<OlPolygon>;
  showPixelGraph: number;
  onToggleDraw?: (isActive: boolean) => void;
};

const ANALYSIS_AREA_LIMIT_KM2 = 3.0;

const POLY = "Polygon";
const PT = "Point";

type PointType = "Point";
type PolygonType = "Polygon";
type DrawType = PointType | PolygonType | null;

type PointData = {
  type: PointType,
  map: OlMap,
  pixel: number[],
  coord: number[],
  aoiCode: string
}

type PolygonData = {
  type: PolygonType,
  map: OlMap,
  geom: OlPolygon,
  size_km2: number,
  aoiCode: string
}

type SeriesData = {
  name: string,
  type: string,
  data: number[]
}

function AnalysisPane(props: AnalysisPaneProps) {
  const mapContext = useContext(MapContext) as MapContextType;
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [aoi, setAOI] = useState<PickerItem | null>(null);
  const [productType, setProductType] = useState<PickerItem | null>({ label: "CHL", value: "chl" });
  const [dates, setDates] = useState<Array<string>>([]);

  const [locationPointData, setLocationPointData] = useState<PointData | null>(null);
  const [locationPolygonData, setLocationPolygonData] = useState<PolygonData | null>(null);
  const [graphPointData, setGraphPointData] = useState<any | null>(null);
  const [graphPolyData, setGraphPolyData] = useState<any | null>(null);

  const [isDrawing, setIsDrawing] = useState(false);
  const [drawType, setDrawType] = useState<DrawType>(null);

  const drawingPointSourceRef = useRef<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const drawPointSourceRef = useRef<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const drawingPolygonSourceRef = useRef<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const drawPolygonSourceRef = useRef<OlSourceVector<Geometry>>(new OlSourceVector({}));

  const [drawingPointLayer, setDrawingPointLayer] = useState<OlLayerVector<OlSourceVector<Geometry>>>(
    new OlLayerVector({ source: drawingPointSourceRef.current })
  );
  const [drawPointLayer, setDrawPointLayer] = useState<OlLayerVector<OlSourceVector<Geometry>>>(
    new OlLayerVector({ source: drawPointSourceRef.current, style: analysisStyle })
  );

  const [drawingPolygonLayer, setDrawingPolygonLayer] = useState<OlLayerVector<OlSourceVector<Geometry>>>(
    new OlLayerVector({ source: drawingPolygonSourceRef.current })
  );
  const [drawPolygonLayer, setDrawPolygonLayer] = useState<OlLayerVector<OlSourceVector<Geometry>>>(
    new OlLayerVector({ source: drawPolygonSourceRef.current, style: analysisStyle })
  );

  const [drawPointInteraction, setDrawPointInteraction] = useState(
    new Draw({
      source: drawPointSourceRef.current,
      type: PT,
      style: analyzingStyle
    })
  );

  const [drawPolygonInteraction, setDrawPolygonInteraction] = useState(
    new Draw({
      source: drawPolygonSourceRef.current,
      type: POLY,
      style: analyzingStyle
    })
  );

  const { onLoaded, onClear, aoiFeature, showPixelGraph, onToggleDraw, layers, aoiRecords } = props;

  useEffect(() => {
    if (onToggleDraw) {
      onToggleDraw(true);
    }
    return () => {
      drawPointInteraction.setActive(false);
      drawPolygonInteraction.setActive(false);

      //@ts-ignore
      drawingPointLayer.setMap(null);
      //@ts-ignore
      drawPointLayer.setMap(null);

      //@ts-ignore
      drawingPolygonLayer.setMap(null);
      //@ts-ignore
      drawPolygonLayer.setMap(null);

      if (onToggleDraw) {
        onToggleDraw(false);
      }

    }
  }, [])

  useEffect(() => {
    if (mapContext && mapContext.map && aoiFeature) {
      const view = mapContext.map.getView();
      const extent = aoiFeature.getGeometry()?.getExtent();
      if (extent) {
        const center = OlGetCenter(extent);
        if (view && extent) {
          view.animate({ center: center, zoom: 13 })
        }
      }
    }
  })

  useEffect(() => {
    if (mapContext.map) {
      // register point source, layer and interaction
      drawingPointLayer.setMap(mapContext.map);
      drawPointLayer.setMap(mapContext.map);

      drawPointInteraction.on("drawstart", handleDrawPointStart);
      drawPointInteraction.on("drawend", handleDrawPointEnd);
      mapContext.map.addInteraction(drawPointInteraction);
      drawPointInteraction.setActive(false);

      // register polygon source, layer and interaction
      drawingPolygonLayer.setMap(mapContext.map);
      drawPolygonLayer.setMap(mapContext.map);

      drawPolygonInteraction.on("drawstart", handleDrawPolygonStart);
      drawPolygonInteraction.on("drawend", handleDrawPolygonEnd);
      mapContext.map.addInteraction(drawPolygonInteraction);
      drawPolygonInteraction.setActive(false);
    }

  }, [mapContext.map])

  useEffect(() => {
    if (aoi && productType && dates.length > 0 && isDrawing === false) {
      drawPointInteraction.setActive(true);
    } else {
      drawPointInteraction.setActive(false);
    }
  }, [aoi, productType, dates, isDrawing])

  useEffect(() => {
    if (locationPointData) {
      analyzePoint();
    }
  }, [locationPointData])

  useEffect(() => {
    if (Array.isArray(aoiRecords) && aoiRecords.length === 1) {
      const rec = aoiRecords[0];
      setAOI({
        label: rec.title as string,
        value: rec.code as string
      })
    }
  }, [aoiRecords])

  // const handleSelectPoint = useCallback(() => {
  //   if (drawType !== PT) {
  //     toggleDraw(true, PT);
  //     snackbarContext.showNotification("map:analysis.helper_point", "info")
  //   } else {
  //     toggleDraw(false, null);
  //   }
  // }, [drawType]);

  const handleSelectPolygon = useCallback(() => {
    if (drawType !== POLY) {
      toggleDraw(true, POLY);
      snackbarContext.showNotification("map:analysis.helper_poly", "info")
    } else {
      toggleDraw(false, null);
    }
  }, [drawType]);

  const handleDrawPointStart = (evt: DrawEvent) => {
    drawPointSourceRef.current.clear();
    // handleClear();
  };

  const getAOIcode = (map: OlMap, pixel: number[]) => {
    const aoiFeature: OlFeature<Geometry> | undefined = map.forEachFeatureAtPixel(
      pixel,
      (ft: any) => { return ft },
      {
        layerFilter: (layer: OlBaseLayer) => {
          const layerId = layer.get("id");
          return layerId === "boundaries" ? true : false;
        }
      }
    );

    return aoiFeature ? aoiFeature.get("code") : null;
  }

  const handleDrawPointEnd = (evt: DrawEvent) => {
    //@ts-ignore
    const map = evt.target.getMap();
    const coord = evt.feature.getGeometry().getCoordinates();
    const pixel = map.getPixelFromCoordinate(coord);

    const aoiCode = getAOIcode(map, pixel);

    setLocationPointData({
      type: PT,
      map: map,
      pixel: pixel,
      coord: coord,
      aoiCode: aoiCode
    })

    // toggleDraw(false, null);
  }

  const handleDrawPolygonStart = (evt: DrawEvent) => {
    drawPolygonSourceRef.current.clear();
    setLocationPolygonData(null);
    setGraphPolyData(null);
  };

  const handleDrawPolygonEnd = (evt: DrawEvent) => {
    //@ts-ignore
    const map = evt.target.getMap();
    const geom = evt.feature.getGeometry() as OlPolygon;
    const coord = geom.getFirstCoordinate();
    const pixel = map.getPixelFromCoordinate(coord);
    const size_m2 = getArea(geom);
    const size_km2 = size_m2 / 1000000;

    const aoiCode = getAOIcode(map, pixel);

    setLocationPolygonData({
      type: POLY,
      map: map,
      geom: geom,
      size_km2: size_km2,
      aoiCode: aoiCode
    })

    if (size_km2 > ANALYSIS_AREA_LIMIT_KM2) {
      snackbarContext.showNotification("map:analysis.poly_too_big", "warning")
    }

    toggleDraw(false, null);
  }

  const toggleDraw = useCallback((isActive: boolean, type: DrawType) => {
    setIsDrawing(isActive);
    setDrawType(type);

    switch (type) {
      // case "Point":
      //   drawPointInteraction.setActive(isActive);
      //   drawPolygonInteraction.setActive(false);
      //   break;
      case "Polygon":
        drawPolygonInteraction.setActive(isActive);
        // drawPointInteraction.setActive(isActive ? false : true);
        break;
      default:
        // drawPointInteraction.setActive(true);
        drawPolygonInteraction.setActive(false);
        break;
    }

    // if (onToggleDraw) {
    //   onToggleDraw(isActive);
    // }
  }, [drawPointInteraction, drawPolygonInteraction]);

  const handleFieldChange = (value: any, source: string) => {
    switch (source) {
      case "product_type":
        setProductType(value);
        break;
      case "aoi":
        setAOI(value);
        break;
    }
  }

  const handleDateRangeChange = (dates: Array<string>) => {
    setDates(dates);
  }

  const handleClear = () => {
    drawPointSourceRef.current.clear();
    drawPolygonSourceRef.current.clear();
    setLocationPointData(null);
    setLocationPolygonData(null);
    setGraphPointData(null);
    setGraphPolyData(null);
    // setProductType(null);
    onClear();
  }

  const getMapLayerId = () => {
    if (aoi && productType) {
      return `app-${aoi.value}-${productType.value}`
    } else {
      return null;
    }
  }

  const analyzePoint = () => {
    if (aoi && productType && locationPointData) {
      const mapLayerId = getMapLayerId();
      const flatLayers = flattenLayers(layers.getArray(), 2).filter(x => x instanceof OlLayerTile)
      const layer = flatLayers.find(x => x.get("id") === mapLayerId);

      if (layer) {
        const gsLayer = layer.get("layer");

        loaderContext.toggleLoading(true);
        analysisService.analyzePoint(gsLayer, dates, locationPointData.map, locationPointData.pixel).then((resp) => {
          if (resp.ok === "ok") {
            setGraphPointData({
              series: [{
                name: "Point value",
                type: "column",
                data: resp.res.map(x => x.value)
              }],
              labels: resp.res.map(x => x.date),
              average: resp.avg ? resp.avg.toFixed(2) : null
            })
          }

        })
          .finally(() => {
            loaderContext.toggleLoading(false);
          })
      }
    }
  }

  const handleAnalyzePolygon = () => {
    if (aoi && productType && locationPolygonData) {

      const mapLayerId = getMapLayerId();
      const flatLayers = flattenLayers(layers.getArray(), 2).filter(x => x instanceof OlLayerTile)
      const layer = flatLayers.find(x => x.get("id") === mapLayerId);

      if (layer) {
        const gsLayer = layer.get("layer");

        loaderContext.toggleLoading(true);
        analysisService.analyzeArea(gsLayer, dates, locationPolygonData.geom).then((resp) => {
          console.log(resp);

          if (resp.ok === "ok") {
            //@ts-ignore
            const avg = resp.avg;
            setGraphPolyData({
              series: [{
                name: "Point value",
                type: "column",
                data: resp.res.map(x => x.value)
              }],
              labels: resp.res.map(x => x.date),
              //@ts-ignore
              average: resp.avg ? resp.avg.toFixed(2) : null
            })
          }
        })
          .finally(() => {
            loaderContext.toggleLoading(false);
          })
      }
    }
  }

  const headingVariant = "subtitle2"

  const fieldAOI: IFieldPickerItems = {
    title: "",
    source: "aoi",
    ttoken: "",
    type: "picker",
    items: {
      labels: aoiRecords.map(x => x.title as string),
      values: aoiRecords.map(x => x.code as string)
    },
    hideHelperText: true,
    readonly: aoiRecords.length === 1 ? true : false
  }

  const fieldProductType: IFieldPickerItems = {
    title: "",
    source: "product_type",
    ttoken: "",
    type: "radio",
    items: {
      labels: ["CHL", "TSM"],
      values: ["chl", "tsm"]
    },
    row: true,
    hideHelperText: true
  }

  const flatLayers = flattenLayers(layers.getArray(), 2).filter(x => x instanceof OlLayerTile)
  const mapLayerId = getMapLayerId();
  const layer = productType ? flatLayers.find(x => x.get("id") === mapLayerId) : null;
  const timeSeriesString = layer?.get("timeseries");
  const timeseries = timeSeriesString ? timeSeriesString.split(";") : undefined;

  // test 100x
  // const timeseries = ['2022-01-01','2022-01-02','2022-01-03','2022-01-04','2022-01-05','2022-01-06','2022-01-07','2022-01-08','2022-01-09','2022-01-10','2022-01-11','2022-01-12','2022-01-13','2022-01-14','2022-01-15','2022-01-16','2022-01-17','2022-01-18','2022-01-19','2022-01-20','2022-01-21','2022-01-22','2022-01-23','2022-01-24','2022-01-25','2022-01-26','2022-01-27','2022-01-28','2022-01-29','2022-01-30','2022-01-31','2022-02-01','2022-02-02','2022-02-03','2022-02-04','2022-02-05','2022-02-06','2022-02-07','2022-02-08','2022-02-09','2022-02-10','2022-02-11','2022-02-12','2022-02-13','2022-02-14','2022-02-15','2022-02-16','2022-02-17','2022-02-18','2022-02-19','2022-02-20','2022-02-21','2022-02-22','2022-02-23','2022-02-24','2022-02-25','2022-02-26','2022-02-27','2022-02-28','2022-03-01','2022-03-02','2022-03-03','2022-03-04','2022-03-05','2022-03-06','2022-03-07','2022-03-08','2022-03-09','2022-03-10','2022-03-11','2022-03-12','2022-03-13','2022-03-14','2022-03-15','2022-03-16','2022-03-17','2022-03-18','2022-03-19','2022-03-20','2022-03-21','2022-03-22','2022-03-23','2022-03-24','2022-03-25','2022-03-26','2022-03-27','2022-03-28','2022-03-29','2022-03-30','2022-04-31','2022-04-01','2022-04-02','2022-04-03','2022-04-04','2022-04-05','2022-04-06','2022-04-07','2022-04-08','2022-04-09','2022-04-10']
  const hasTimeSeriesSlider = timeseries && timeseries.length > 0;
  const hasDrawPolyButton = timeseries && timeseries.length > 0 && !graphPolyData
  const ticks = timeseries ? timeseries.length - 1 : 0;

  const hasGeometry = locationPolygonData !== null;
  const geometryNotTooBig = hasGeometry && locationPolygonData.size_km2 <= ANALYSIS_AREA_LIMIT_KM2 ? true : false;
  const hasProdcutType = productType ? true : false;
  const hasDatesRange = dates.length > 0 ? true : false;

  const ready = hasGeometry && geometryNotTooBig && hasProdcutType && hasDatesRange;

  return (
    <>
      <Typography component="h3" variant={headingVariant} gutterBottom={true}>
        1. {t("map:analysis.step-choose-aoi")}
      </Typography>
      <PickerControl
        field={fieldAOI}
        formMode="form"
        controlMode="edit"
        value={aoi}
        onChange={handleFieldChange}
        size="small"
      />
      <Divider sx={{ my: 1 }} />

      <Typography component="h3" variant={headingVariant} gutterBottom={true}>
        2. {t("map:analysis.step-choose-product")}
      </Typography>
      <RadioControl
        field={fieldProductType}
        value={productType}
        onChange={handleFieldChange}
        formMode="form"
        controlMode="edit"
      />
      <Divider sx={{ mb: 1 }} />

      <Typography component="h3" variant={headingVariant} gutterBottom={true}>
        3. {t("map:analysis.step-choose-dates")} {timeseries ? `(${dates.length}/${timeseries.length})` : null}
      </Typography>
      {hasTimeSeriesSlider ?
        <TimeSeriesRangeSlider
          id={"pick-range"}
          ticks={ticks}
          checked={true}
          timeseries={timeseries}
          onChange={handleDateRangeChange}
        /> : null}
      <Divider sx={{ mb: 1 }} />

      <Typography component="h3" variant={headingVariant} gutterBottom={true}>
        4. {t("map:analysis.step-choose-model")}
      </Typography>
      <Stack direction="row" spacing={1}>
        {/* <Button
          variant={isDrawing && drawType === PT ? "contained" : "outlined"}
          startIcon={<i className="fas fa-map-marker-alt" />}
          disabled={graphPointData || graphPolyData ? true : false}
          onClick={handleSelectPoint}
        >
          {t("map:analysis.averages_point")}
        </Button> */}
        <Button
          variant={isDrawing && drawType === POLY ? "contained" : "outlined"}
          startIcon={<i className="fas fa-vector-square" />}
          disabled={!hasDrawPolyButton}
          onClick={handleSelectPolygon}
        >
          {t("map:analysis.averages_polygon")}
        </Button>
        <Button
          variant={"contained"}
          startIcon={<BarChartIcon />}
          disabled={ready ? false : true}
          onClick={handleAnalyzePolygon}
        >
          {t("map:analysis.analyze")}
        </Button>
        <Button
          variant={"outlined"}
          startIcon={<ReplayIcon />}
          onClick={handleClear}
          sx={{ ml: 1 }}
        >
          {t("map:analysis.new_analysis")}
        </Button>
      </Stack>
      {graphPolyData ? (
        <LineColumn
          series={graphPolyData.series}
          title={t("map:analysis.spatio_temporal.title")}
          labels={graphPolyData.labels}
          yLabel={t("map:analysis.spatio_temporal.y_label")}
          avg={graphPolyData.average}
        />
      ) : null}
      {graphPointData ? (
        <LineColumn
          series={graphPointData.series}
          title={t("map:analysis.temporal.title")}
          labels={graphPointData.labels}
          yLabel={t("map:analysis.temporal.y_label")}
          avg={graphPointData.average}
        />
      ) : null}
    </>
  );
}

export default AnalysisPane;
