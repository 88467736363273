import { IModel } from "@/@types/models/model";

const User: IModel = {
  title: "Users",
  apiPath: "data",
  forms : {
    "default": {
      fields: ["chl", "date"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "objectid", "opis"]
    }
  },
  fields: [
    {
      title: "id",
      source: "id",
      ttoken: "common.id",
      type: "numeric",
      idattr: true,
      readonly: true
    },
    {
      title: "objectid",
      source: "objectid",
      ttoken: "common.objectid",
      type: "numeric",
      readonly: true
    },
    {
      title: "opis",
      source: "opis",
      ttoken: "common.opis",
      type: "text",
      readonly: true
    },
    {
      title: "RED",
      source: "RED_BAND",
      ttoken: "common.colors.red",
      type: "wholenum",
      readonly: true
    },
    {
      title: "GREEN",
      source: "GREEN_BAND",
      ttoken: "common.colors.green",
      type: "wholenum",
      readonly: true
    },
    {
      title: "BLUE",
      source: "BLUE_BAND",
      ttoken: "common.colors.blue",
      type: "wholenum",
      readonly: true
    },
    {
      title: "Latest value",
      source: "latest_value",
      ttoken: "common.latest_value",
      type: "numeric",
      readonly: true
    },
    {
      title: "CHL",
      source: "chl",
      ttoken: "common.chl",
      type: "text",
      suffix: "mg/m³",
      readonly: true
    },
    {
      title: "Timestamp",
      source: "date",
      ttoken: "common.timestamp",
      type: "date",
      readonly: true
    }

  ]
}

export default User;
