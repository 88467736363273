import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import privateRoutes from "@/routes/private";
import publicRoutes from "@/routes/public";

//Custom Components
import PrivatePage from "@/components/PrivatePage";
import ScrollToTop from "@/components/ScrollToTop";
import UserProvider from "@/components/UserContext/UserProvider";
import MapConfigProvider from "@/components/MapConfigContext/MapConfigProvider";
import ThemeProvider from "@/components/ThemeContext/ThemeProvider";
import SnackbarProvider from "@/ui/SnackbarContext/SnackbarProvider";
import SnackbarContainer from "@/ui/SnackbarContext/SnackbarContainer";
import DialogProvider from "@/ui/DialogContext/DialogProvider";
import DialogContainer from "@/ui/DialogContext/DialogContainer";
import LoaderProvider from "@/components/LoaderContext/LoaderProvider";
import LoaderContainer from "@/components/LoaderContext/LoaderContainer";

var history = createBrowserHistory();

class App extends React.Component {
  render() {
    return (
      <ThemeProvider>
        <UserProvider>
          <SnackbarProvider>
            <LoaderProvider>
              <MapConfigProvider>
                <DialogProvider>
                  <BrowserRouter basename={process.env.REACT_APP_DEPLOYFOLDER}>
                    <ScrollToTop>
                      <Routes>
                        {publicRoutes.map((prop, key) => (
                          <Route path={prop.path} key={key} element={React.createElement(prop.component)} />
                        ))}
                        {privateRoutes.map((prop, key) => (
                          <Route path={prop.path} key={key} element={<PrivatePage>{prop.component}</PrivatePage>} />
                        ))}
                        <Route path='*' element={ <Navigate to='/' />}/>
                      </Routes>
                    </ScrollToTop>
                    <DialogContainer />
                  </BrowserRouter>
                </DialogProvider>
              </MapConfigProvider>
              <LoaderContainer />
              <SnackbarContainer />
            </LoaderProvider>
          </SnackbarProvider>
        </UserProvider>
      </ThemeProvider>
    );
  }
}

export default App;
