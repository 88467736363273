import * as React from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI Core Components
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";

//Custom Components
import { GridContainer, GridItem } from "@/ui/Grid";

//logos
import Zicer from "@/images/partner_logos/zicer.png";
import Parsec from "@/images/partner_logos/parsec.png";
import Aaa from "@/images/partner_logos/aaa.png";
import Euspa from "@/images/partner_logos/euspa.png";
import EU from "@/images/partner_logos/eu.png";
import BlueInvest from "@/images/partner_logos/blue-invest.png";
import SeaCras from "@/images/logo/seacras.png";
import Prehnit from "@/images/logo/prehnit.png";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const textVariant = "caption";
  const version = process.env.REACT_APP_APPVERSION;
  const language = i18n.language;
  const currentYear = new Date().getFullYear();

  const imageHeight = 120;

  return (
    <footer id="footer">
      <Container
        maxWidth={false}
        sx={{
          borderTop: "2px solid #000",
          backgroundColor: "#fff"
        }}
      >
        <GridContainer columns={6} alignItems="center" justifyContent="center">
          {/* <GridItem xs={8} sm={4} lg={2} xl={1} sx={{textAlign: "center"}} className="noselect">
            <img src={Zicer} height={imageHeight}/>
          </GridItem> */}
          {/* <GridItem xs={8} sm={4} lg={2} xl={1} sx={{textAlign: "center"}}  className="noselect">
            <img src={Parsec} height={imageHeight} />
          </GridItem> */}
          {/* <GridItem xs={8} sm={4} lg={2} xl={1} sx={{textAlign: "center"}} className="noselect">
            <img src={Aaa} height={imageHeight} />
          </GridItem> */}
          <GridItem xs={8} sm={4} lg={2} xl={1} sx={{textAlign: "center"}} className="noselect">
            <img src={Euspa} height={imageHeight} />
          </GridItem>
          <GridItem xs={8} sm={4} lg={2} xl={1} sx={{textAlign: "center"}} className="noselect">
            <a href='https://seacras.com/' target="_blank" >
              <img src={SeaCras} height={imageHeight - 20} />
            </a>
          </GridItem>
          <GridItem xs={8} sm={4} lg={2} xl={1} sx={{textAlign: "center"}} className="noselect">
            <a href='https://prehnit.hr/hr/' target="_blank">
              <img src={Prehnit} height={imageHeight - 20} />
            </a>
          </GridItem>
          {/* <GridItem xs={8} sm={4} lg={2} xl={1} sx={{textAlign: "center"}} className="noselect">
            <img src={BlueInvest} height={imageHeight} />
          </GridItem>
          <GridItem xs={8} sm={4} lg={2} xl={1} sx={{textAlign: "center"}} className="noselect">
            <img src={EU} height={imageHeight} />
          </GridItem> */}
          {/* <GridItem xs={8} lg={2}>
            <Typography variant="caption" color='#000' paragraph={true} gutterBottom={false} sx={{ mb: 1 }}>
              {t("footer.supported_by")}
            </Typography>
            <Typography variant="caption" color='#000' paragraph={true} gutterBottom={false} sx={{ mb: 0 }}>
              {t("footer.funding")}
            </Typography>
          </GridItem> */}
        </GridContainer>
      </Container>
      {/* <Stack
        direction="row"
        spacing={0}
        justifyContent="center"
        alignItems="center"
        sx={{
          borderTop: "2px solid #000",
          backgroundColor: "#fff"
        }}
      >
        <img src={Zicer} height={imageHeight} />
        <img src={Parsec} height={imageHeight} />
        <img src={Aaa} height={imageHeight} />
        <img src={Euspa} height={imageHeight} />
        <img src={BlueInvest} height={imageHeight} />
        <img src={EU} height={imageHeight} />
        <Box p={1} sx={{ minWidth: 400, maxWidth: 500 }}>
          <Typography variant="caption" paragraph={true} gutterBottom={false} sx={{ mb: 1 }}>
            {t("footer.supported_by")}
          </Typography>
          <Typography variant="caption" paragraph={true} gutterBottom={false} sx={{ mb: 0 }}>
            {t("footer.funding")}
          </Typography>
        </Box>
      </Stack> */}
    </footer>
  );
};

export default Footer;
