import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";

//Material-UI Core Components
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

//Material-UI Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

//Custom Components
import MapCard from "@/ui/MapCard/MapCard";
import MapCardHeader from "@/ui/MapCard/MapCardHeader";
import MapCardAvatar from "@/ui/MapCard/MapCardAvatar";
import MapCardContent from "@/ui/MapCard/MapCardContent";
import MapCardActions from "@/ui/MapCard/MapCardActions";
import MapCardActionButton from "@/ui/MapCard/MapCardActionButton";
import { formats } from "@/lib/formats";
import dataController from "@/lib/dataController";
import MapCardHeaderButtonClose from "@/ui/MapCard/MapCardHeaderButtonClose";
import DialogContext from "@/ui/DialogContext/DialogContext";
import { GridContainer, GridItem } from "@/ui/Grid";
import ModelTable from "@/ui/Table/ModelTable";
// import { dataService } from "@/services/dataService";
import FormContent from "@/components/FormContent";
import UserContext from "@/components/UserContext/UserContext";

// import modelPregled from "Models/pregledi";
// import ImageSlider from "@/components/ImageSlider";

// import modelCvjetnjaci from "Models/cvjetnjaci";
// import modelGrmovi from "Models/grmovi";
// import modelKlupe from "Models/klupe";
// import modelKosevi from "Models/kosevi";
// import modelPutevi from "Models/putevi";
// import modelStabla from "Models/stabla";
// import modelSumskeSastojine from "Models/sumske-sastojine";
// import modelTravnjaci from "Models/travnjaci";
// import modelPhotos360 from "Models/photos360";
import modelData from "@/models/data";

// import DataDialog from "Views/MapPage/DataDialog";
// import InspectionDialog from "Views/MapPage/InspectionDialog";

//Types
import { GSInfoCardProps } from "@/@types/views/GIS/map";

function GSInfoCard(props: GSInfoCardProps) {
  const { t } = useTranslation();

  const initialRecod = props.featureData.record ? props.featureData.record
    : props.featureData.feature ? props.featureData.feature.properties
    : {};

  const [record, setRecord] = useState(initialRecod);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [loadStatus, setLoadStatus] = useState({ isLoading: true, isLoaded: false });
  const [imageLoadStatus, setImageLoadStatus] = useState({ imageLoading: true });
  const [expanded, setCollapse] = useState(false);
  const [inspections, setInspections] = useState(null);

  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const { featureData, onClose } = props;

  const { type } = featureData;

  const getModel = () => {
    switch (type) {
      // case "stabla":
      //   return modelStabla;
      // case "grmovi":
      //   return modelGrmovi;
      // case "klupe":
      //   return modelKlupe;
      // case "kosevi":
      //   return modelKosevi;
      // case "travnjaci":
      //   return modelTravnjaci;
      // case "cvjetnjaci":
      //   return modelCvjetnjaci;
      // case "sumske-sastojine":
      //   return modelSumskeSastojine;
      // case "putevi":
      //   return modelPutevi;
      // case "photos360":
      //   return modelPhotos360;
      default:
        return modelData;
    }
  };

  const model = getModel();

  const dc = new dataController(model);

  const faIconClass = "fas fa-info ";

  const handleRefresh = function () {
    setRefreshCounter(refreshCounter + 1);

    setLoadStatus({ isLoading: true, isLoaded: false });
  };

  const handleDetailCollapse = function () {
    setCollapse(!expanded);
  };

  const handleEdit = () => {
    // const model = getModel(type);
    // const dc = new dataController(model);
    // dialogContext.showDialog(DataDialog, {
    //   type: type,
    //   dc: dc,
    //   recordId: record.id,
    //   mode: "update",
    //   form: "edit",
    //   iconClass: faIconClass,
    //   onClose: (result) => handleDataDialogClose(result, record.id)
    // });
  };

  // const handleDataDialogClose = (dialogResult, recordId) => {
  //   if (dialogResult.dataChanged) {
  //     // dataService.getData(recordId).then((result) => {
  //     //   if (result.success) {
  //     //     setRecord(result.data);
  //     //   }
  //     // });
  //   }
  // };

  // const refreshInspections = () => {
  //   // dataService.getPreglediById(record.id).then((resp) => {
  //   //   if (resp) {
  //   //     setInspections(resp);
  //   //   } else {
  //   //     setInspections(null);
  //   //   }
  //   //   setLoadStatus({
  //   //     isLoading: false,
  //   //     isLoaded: true
  //   //   });
  //   // });
  // };

  // useEffect(() => {
  //   refreshInspections();
  // }, [record.id, refreshCounter]);

  useEffect(() => {
    if (featureData) {
      if (featureData.record) {
        setRecord(featureData.record);
      } else if (featureData.feature) {
        setRecord(featureData.feature.properties);
      }
    }
    setRecord;
  }, [featureData]);

  const form = "default";
  const title = record.naziv ? record.naziv : type ? t("map:layer." + type) : "Info";

  return (
    <Fragment>
      <MapCard
        sx={{
          backgroundColor: "background.default",
          minWidth: "200px"
        }}
      >
        <MapCardHeader
          title={title}
          subheader={record.id ? (t("common.id") + ": " + record.id) : undefined}
          subheaderTypographyProps={{ color: "initial" }}
          avatar={<MapCardAvatar ariaLabel="info" icon={<i className={faIconClass}></i>} />}
          action={<MapCardHeaderButtonClose onClick={onClose} />}
        />
        <MapCardContent>
          <GridContainer spacing={0}>
            <GridItem xs={12} container>
              {/* <Form dc={dc} mode="view" form={form} record={record}> */}
                <GridContainer spacing={0}>
                <FormContent
                  // dc={dc}
                  // form={form}
                  fields={dc.getFormFields(form)}
                  fieldNames={dc.getFormFieldsNames(form)}
                  columns={1}
                  mode="simpleView"
                  record={record}
                />
                </GridContainer>
              {/* </Form> */}
            </GridItem>
            {/* <GridItem xs={6}>Image Placeholder</GridItem> */}
          </GridContainer>
        </MapCardContent>
      </MapCard>
    </Fragment>
  );
}

export default GSInfoCard;
