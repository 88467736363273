import React, { useContext, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

// d.ts
import { DCRecord, IDataController } from "@/@types/lib/dataController"

// Material UI Core
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


// Custom Components
import ModelTable from "@/ui/Table/ModelTable";
import DialogContext from "@/ui/DialogContext/DialogContext";
import LoaderContext from "@/components/LoaderContext/LoaderContext"
import ImageLogDialog from "./ImageLogDialog";

import model from "@/models/images";
import dataController from "@/lib/dataController";
import { DialogContextType } from "@/@types/ui/DialogContext";

// Services
import imagesService from "@/services/imagesService";

//Types
import { LoaderContextType } from "@/@types/context/LoaderContext";


// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

function ImagesTable() {
  const dialogContext = useContext<DialogContextType>(DialogContext);
  const loaderContext = useContext<LoaderContextType>(LoaderContext);
  const { t } = useTranslation();

  const [records, setRecords] = useState<DCRecord[]>([]);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const dc: IDataController = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData().then((resp) => {
      if (resp.success) {
        if (Array.isArray(resp.data)) {
          setRecords(resp.data)
          setRefreshCounter((prevState) => { return prevState + 1 });
        }
        else {
          setRecords([]);
          setRefreshCounter((prevState) => { return prevState + 1 });
        }
      };
    })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowAction = useCallback((action: string, id: number) => {
    switch (action) {
      case "visibility":
        const rec = records.find(x => x.id === id);
        if (rec) {
          const currentVisible = rec.active;
          const newVisibile = !currentVisible;
          if (newVisibile === true) {
            imagesService.activate(id).then((resp) => {
              refreshRecords();
            })

          } else {
            imagesService.deactivate(id).then((resp) => {
              refreshRecords();
            });
          }
        }
        break;
      case "view_log":
        dialogContext.showDialog(ImageLogDialog, {
          recordId: id
        })
        break;
    }
  }, [records.length, refreshCounter]);

  const allowRowAction = (action: string, id: number) => {
    return true;
  }

  const determineIcon = (action: string, id: number) => {
    if (action === 'visibility') {
      const rec = records.find(x => x.id === id);
      if (rec && rec.active) {
        return "visibility"
      } else {
        return "visibility_off"
      }
    }

    return "visibility"
  }

  return (
    <Card>
      <CardContent>
        <ModelTable
          title={t("titles.images")}
          records={records}
          dc={dc}
          allowSelection="none"
          allowFilter={true}
          allowExport={true}
          allowAdd={false}
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          determineIcon={determineIcon}
        >
        </ModelTable>
      </CardContent>
    </Card>
  );
}

export default ImagesTable;
