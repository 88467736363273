import React, { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

//MUI Icons
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

//Custom Components
import TableHeaderButton from "./TableHeaderButton";
import { IMenuState, ITableHeaderButtonColumnPickerProps } from "@/@types/ui/Table";

function TableHeaderButtonColumnPicker(props: ITableHeaderButtonColumnPickerProps) {
  const { t } = useTranslation();
  const { columns, hiddenColumnNames, onChange, ...rest } = props;

  const [menuState, setMenuState] = useState<IMenuState>({ open: false, anchorEl: null });

  const handleOpen = (evt: MouseEvent<HTMLButtonElement>) => {
    setMenuState({ open: true, anchorEl: evt.currentTarget });
  };

  const handleToggle = (evt: MouseEvent<HTMLLIElement>, source: string) => {
    const isVisible = hiddenColumnNames ? !hiddenColumnNames.includes(source) : true;
    const newState = !isVisible;

    if (onChange) {
      onChange(source, newState);
    }
  };

  const handleClose = (evt: MouseEvent<HTMLButtonElement>) => {
    setMenuState({ open: false, anchorEl: null });
  };

  return (
    <>
      <TableHeaderButton startIcon={<ViewColumnIcon />} variant="outlined" {...rest} onClick={handleOpen}>
        {t("buttons.toggle_columns")}
      </TableHeaderButton>
      <Menu
        open={menuState.open}
        onClose={handleClose}
        anchorEl={menuState.anchorEl}
        transformOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
      >
        {columns.map((c, i) => {
          return c.accessor && c.Header ? (
            <MenuItem value={i} onClick={(evt) => handleToggle(evt, c.accessor as string)} key={i}>
              <Switch
                color="primary"
                checked={hiddenColumnNames ? !hiddenColumnNames.includes(c.accessor as string) : true}
                value={i ? undefined : 'false'}
              />
              <Typography variant="caption" style={{ textTransform: "uppercase", fontWeight: 600 }}>
                {c.Header}
              </Typography>
            </MenuItem>
          ) : null;
        })}
      </Menu>
    </>
  );
}

export default TableHeaderButtonColumnPicker;
