import HomePage from "@/views/HomePage/HomePage";
import AppMapPage from "@/views/AppPage/AppMapPage";
import ProfilePage from "@/views/ProfilePage/ProfilePage";
import AppUsersPage from "@/views/AppPage/AppUsersPage";
import ImagesPage from "@/views/ImagesPage/ImagesPage";

//Types
import { RouteType } from "@/@types/common";

const privateRoutes: RouteType[] = [
  { path: "/profile", name: "Profile", component: ProfilePage},
  { path: "/map", name: "Map", component: AppMapPage },
	{ path: "/users", name: "Map", component: AppUsersPage },
  { path: "/datasets", name: "Images", component: ImagesPage },

	{ path: "/", name: "Home", component: AppMapPage }
];

export default privateRoutes
