import React, { useContext, useEffect, useState } from "react";

//MUI
import Slider from "@mui/material/Slider";

//Components
import MapContext from "@/components/Map/MapContext";

//Types
import { MapContextType } from "@/@types/context/MapContext";

interface ITimeSeriesSlider {
  id: any;
  ticks: number;
  checked: string | boolean | undefined;
  timeseries: Array<string>,
  syncGroup?: boolean
}

function TimeSeriesSlider(props: ITimeSeriesSlider) {
  const { id, ticks, checked, timeseries, syncGroup } = props
  const [ value, setValue ] = useState(0)

  const mapContext = useContext(MapContext) as MapContextType;

  const currentTimes = mapContext.getLayerTime()
  const contextValue = timeseries.indexOf(currentTimes[id])

  const setInitialValues = () => {
    const currentTimes = mapContext.getLayerTime()
    let val = timeseries.indexOf(currentTimes[id])
    val = val >= 0 ? val : 0 // makes sure value is set to 0 instead of -1 if there is no layerstime in localstorage
    if (timeseries && timeseries.length > val) {
      setValue(val)
      mapContext.setLayerTime(id, timeseries[val], syncGroup ? true: false)
    }
  }

  const handleChange = (event: React.SyntheticEvent | Event, val: number | Array<number>) => {
    setValue(val as number)  // Type Assertion is ok as long as slider is not a range slider
    const time = handleValueLabel(val as number);
    if (id && time) {
      mapContext.setLayerTime(id, time, syncGroup ? true : false);
    }
  };

  const handleValueLabel = (x: number) => {
    if (timeseries && timeseries.length > x) {
      return timeseries[x];
    } else {
      return '?'
    }
  };

  const getValue = () => {
    return value
  }

  useEffect(() => {
    setInitialValues()
  }, [])

  useEffect(() => {
    setValue(contextValue);
  }, [contextValue])

    return (
        <Slider
          size="medium"
          aria-label="Timestamp"
          value={getValue()}
          valueLabelDisplay="auto"
          valueLabelFormat={handleValueLabel}
          step={1}
          marks
          min={0}
          max={ticks}
          onChange={handleChange}
          sx={{
            width: 100,
            mr: 3
          }}
          disabled={ checked ? false : true }
        />
    )
}

export default TimeSeriesSlider