import React, { Fragment, useState, useContext, useCallback, useRef } from "react";
import clsx from "clsx";

//MUI
import Box from "@mui/material/Box";

//Openlayers
import { toStringHDMS as OlToStringHDMS } from "ol/coordinate";
import { transform } from "ol/proj";

//Components
import MapContext from "@/components/Map/MapContext";
import ButtonControl from "../../OpenLayersExt/ButtonControl";
import makeMUIControlStyle from "../../makeMUIControlStyle";
import CrosshairRoot from "@/components/Map/Controls/Custom/CenterMarker/CrosshairRoot";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { ICenterMarkerControl } from "@/@types/components/Map/Controls/Custom";
import { registerOlControl } from "@/lib/olHelpers";
import { MapEvent } from "ol";

export default function CenterMarkerControl(props: ICenterMarkerControl) {
  const snackbarContext = useContext(SnackbarContext);
  const mapContext = useContext(MapContext) as MapContextType;

  const [active, setActive] = useState(false);
  const classes = makeMUIControlStyle();

  const r = useRef<HTMLDivElement | null>(null);

  const { tooltip, target, className } = props;

  const handleToggle = useCallback(() => {
    setActive((prev) => !prev);

    if (active) {
      //unregister control
    } else {
      //register control
      // registerOlControl(context, crosshairs, {}, {}, {});
    }
  }, [active]);

  const handleClick = () => {
    const view = mapContext?.map?.getView();
    if (view) {
      const proj = view.getProjection();
      const center = view.getCenter();
      if (center) {
        const wgsDisplayCoordinates = OlToStringHDMS(transform(center, proj.getCode(), "EPSG:4326"), 2)
        navigator.clipboard.writeText(wgsDisplayCoordinates);
        if (snackbarContext !== null) {
          snackbarContext.showNotification("messages.coordinates_copy_clipboard", "info");
        }
      }
    }

  }

  const tickStyle = {
    position: "relative",
    border: 2,
    borderRadius: 1,
    borderColor: "primary.main"
  };

  const tickHor = {
    ...tickStyle,
    height: 4,
    width: 16,
    top: "50%",
    marginTop: "-2px"
  };

  const tickVert = {
    ...tickStyle,
    width: 4,
    height: 16,
    left: "50%",
    marginLeft: "-2px"
  };

  return (
    <>
      <ButtonControl
        id="center-marker-btn"
        className={clsx(classes.control, className, "ol-center-marker")}
        title={tooltip}
        html={`<i class="${active ? "far fa-circle" : "fas fa-crosshairs"}"></i>`}
        handleClick={handleToggle}
        target={target}
      />
      <CrosshairRoot>
        <Box
          m={0}
          p={0}
          sx={{
            display: active ? "block" : "none",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: 50,
            height: 50,
            mt: "-25px",
            ml: "-25px",
            backgroundColor: "rgba(0,0,0,0.1)",
            border: 4,
            borderColor: "primary.main",
            borderRadius: "50%",
            zIndex: 1
          }}
          onClick={handleClick}
        >
          <Box
            m={0}
            p={0}
            sx={{
              ...tickHor,
              left: 0,
              marginLeft: "-10px"
            }}
          />
          <Box
            m={0}
            p={0}
            sx={{
              ...tickHor,
              right: 0,
              marginLeft: "36px",
              marginTop: "-4px"
            }}
          />
          <Box
            m={0}
            p={0}
            sx={{
              ...tickVert,
              top: 0,
              marginTop: "-12px"
            }}
          />
          <Box
            m={0}
            p={0}
            sx={{
              ...tickVert,
              bottom: 0,
              marginTop: "30px"
            }}
          />
        </Box>
      </CrosshairRoot>
    </>
  );
}
