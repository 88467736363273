import { IModel } from "@/@types/models/model";

import { coreFields } from "./helpers/coreFields";

const AreasOfInterest: IModel = {
  title: "Images",
  apiPath: "images",
  forms : {
    "default": {
      fields: ["id"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "aoi_title", "image_date", "platform_name", "product_type", "product_title", "image_resolution_m", "image_state_id", "processed_on", "published_on", "has_rgb", "has_chl", "has_tsm", "active", "visibility", "view_log"],
      hidden: ["platform_name", "product_type", "product_title", "active"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.active,
    {
      title: "aoi_title",
      source: "aoi_title",
      ttoken: "image.aoi",
      type: "text",
      translate: true,
      filter: true
    },
    {
      title: "platform_name",
      source: "platform_name",
      ttoken: "image.platform_name",
      type: "text",
      filter: true
    },
    {
      title: "product_type",
      source: "product_type",
      ttoken: "image.product_type",
      type: "text",
      filter: true
    },
    {
      title: "product_title",
      source: "product_title",
      ttoken: "image.product_title",
      type: "text",
      filter: true
    },
    {
      title: "product_id",
      source: "product_id",
      ttoken: "image.product_id",
      type: "text",
      filter: true
    },
    {
      title: "begin_position",
      source: "begin_position",
      ttoken: "image.begin_position",
      type: "datetime",
      filter: true
    },
    {
      title: "end_position",
      source: "end_position",
      ttoken: "image.end_position",
      type: "datetime",
      filter: true
    },
    {
      title: "image_resolution_m",
      source: "image_resolution_m",
      ttoken: "image.image_resolution_m",
      type: "wholenum",
      filter: true
    },
    {
      title: "image_state_id",
      source: "image_state_id",
      ttoken: "image.image_state_id",
      type: "picker",
      subModel: {
        apiPath: "image-states",
        format: "{label}",
      },
      translate: true,
      filter: true
    },
    {
      title: "processed_on",
      source: "processed_on",
      ttoken: "image.processed_on",
      type: "datetime",
      filter: true
    },
    {
      title: "published_on",
      source: "published_on",
      ttoken: "image.published_on",
      type: "datetime",
      filter: true
    },
    {
      title: "has_rgb",
      source: "has_rgb",
      ttoken: "image.has_rgb",
      type: "boolean",
      items: {
        values: [ false, true],
        labels: ["common.no", "common.yes"]
      },
      filter: true
    },
    {
      title: "has_chl",
      source: "has_chl",
      ttoken: "image.has_chl",
      type: "boolean",
      items: {
        values: [ false, true],
        labels: ["common.no", "common.yes"]
      },
      filter: true
    },
    {
      title: "has_tsm",
      source: "has_tsm",
      ttoken: "image.has_tsm",
      type: "boolean",
      items: {
        values: [ false, true],
        labels: ["common.no", "common.yes"]
      },
      filter: true
    },
    {
      title: "image_date",
      source: "image_date",
      ttoken: "image.image_date",
      type: "date",
      filter: true
    },

    {
      title: "",
      source: "visibility",
      tooltip: "buttons.visibility",
      ttoken: "image.visibility",
      type: "button",
      icon: "visibility",
      action: "visibility",
      padding: "none",
      sort: false,
      iconFn: true
    },
    {
      title: "",
      source: "view_log",
      tooltip: "buttons.view_log",
      ttoken: "image.log",
      type: "button",
      icon: "toc",
      action: "view_log",
      padding: "none",
      sort: false
    }
    

  ]
}

export default AreasOfInterest;
