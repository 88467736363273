import React from "react";
import { useTranslation } from "react-i18next";

import AppPage from "@/ui/AppPage/AppPage";
import MainMap from "@/views/GIS/MapPage/MainMap";

function AppMapPage() {

  return (
    <AppPage>
      <MainMap />
    </AppPage>
  );
}

export default AppMapPage;