import api from "@/lib/api";
import gsService from "@/services/gsService";

// OpenLayers
import OlMap from "ol/Map";
import OlPolygon from "ol/geom/Polygon";

type AnalysisRecord = {
  date: string,
  value: number | null | undefined
}

export type AnalysisData = {
  ok: "ok",
  res: AnalysisRecord[],
  avg: number | null
}

export type AnalysisError = {
  ok: "error"
}

interface IAnalysisService {
  analyzePoint: (layer: string, dates: string[], map: OlMap, pixel: number[]) => Promise<AnalysisData | AnalysisError>
  analyzeArea: (layer: string, dates: string[], poly: OlPolygon) => Promise<AnalysisData | AnalysisError>
}

const analysisService: IAnalysisService = {
  analyzePoint,
  analyzeArea,
};

const analysisUrl = "analysis";

function _arrAvg(arr: number[]) {
  return arr.reduce((a: number, b: number) => a + b, 0) / arr.length
}

function analyzePoint(layer: string, dates: string[], map: OlMap, pixel: number[]) {
  return Promise.all(dates.map(dt => gsService.getFeatureInfo(map, pixel, [layer], dt))).then((responses) => {
    if (Array.isArray(responses) && responses.length > 0) {

      const values = responses.map(resp => {
        if (resp && Object.keys(resp).length != 0) {
          const layerKeys = Object.keys(resp);
          const features = resp[layerKeys[0]];
          const firstLayer = resp[layerKeys[0]];
          const firstFeature = firstLayer[0];
          const val = firstFeature.properties.hasOwnProperty("GRAY_INDEX") ? firstFeature.properties.GRAY_INDEX : null;
          return typeof val === "string" ? parseInt(val) : val;
        } else {
          return 0;
        }

      });

      const existingValues = values.filter(x => x !== 0 && x !== undefined && x !== null) as number[];
      const existingValuesAvg = existingValues.length > 0 ? _arrAvg(existingValues) : 0;
      // console.log(values, existingValuesAvg);

      return {
        ok: "ok",
        res: dates.map((dt, i) => { 
          const v = values[i]
          const correctedValue = (v === null || v === undefined || v === 0 ) ? null : v;
          return { 
            date: dt, 
            value: correctedValue 
          };
        }),
        avg: existingValuesAvg
      } as AnalysisData;
    } else {
      return {
        ok: "error"
      } as AnalysisError;
    }
  })
    .catch((resp) => {
      return {
        ok: "error"
      } as AnalysisError
    })
}

function analyzeArea(layer: string, dates: string[], poly: OlPolygon): Promise<AnalysisData | AnalysisError> {
  const apiInstance = new api();
  const url = analysisUrl + "/zone-avg";

  const data = {
    layername: layer,
    datearr: dates,
    zonepoly: poly.getCoordinates()
  }

  return apiInstance.Call(url, "post", data)
    .then((resp) => {
      if (resp.success) {

        //@ts-ignore
        const res = resp.data.res.map(x => { return { date: x.date, value: x.avg}}) as AnalysisRecord[];

        const resCorrected = res.map(x => {
          if (x.value === null || x.value === undefined || x.value === 0) {
            return {
              date: x.date,
              value: null
            }
          }
          else if (x.value > 0 && x.value < 0.01) {
            return {
              date: x.date,
              value: 0.01
            }
          } else {
            return x;
          }
        })

        const existingValues = res.filter(x => x.value !== 0 && x.value !== null && x.value !== undefined).map(x => x.value as number);
        const existingValuesAvg = existingValues.length > 0 ? _arrAvg(existingValues) : null;

        const data: AnalysisData = {
          ok: "ok",
          res: resCorrected,
          avg: existingValuesAvg
        }
        return data;
      } else {
        return { ok: "error" } as AnalysisError
      }
    })
}

export default analysisService;
