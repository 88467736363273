import * as React from "react";
import { FunctionComponent, useContext, useEffect, useRef } from "react";

//MUI Components
import Box from "@mui/material/Box";


//Custom components
import MapContext from "@/components/Map/MapContext";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";
import Status from "./ol5-status";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IStatusRoot } from "@/@types/components/Map/Controls/Status";

const StatusRoot: FunctionComponent<IStatusRoot> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const statusRef = useRef(props.ref);

  useEffect(() => {
    if (statusRef.current) {
      const options = {
        element: statusRef.current,
      };

      let allOptions = Object.assign(options, props);
      let definedOptions = getDefinedOptions(allOptions);

      const events = {};

      let control = new Status(definedOptions);

      if (props.id) {
        control.set("id", props.id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, Status);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control);
      } else {
        mapContext.initOptions.controls.push(control);
      }

      let olEvents = getEvents(events, props);
      for (let eventName in olEvents) {
        //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, Status);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (

    <Box id="status-control"
         ref={statusRef} sx={{
          position: "absolute",
          maxWidth: 300,
          height: 20,
          backgroundColor: "primary.main",
          opacity: 0.75,
          color: "white",
          bottom: 36,
          right: 8,
          margin: "auto",
          borderRadius: 1
         }}>
      {props.children}
    </Box>

  );
};

export default StatusRoot;

