import React, { useContext, useEffect, useState } from "react";

//MUI
import Slider from "@mui/material/Slider";

//Components
import MapContext from "@/components/Map/MapContext";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { start } from "repl";

interface ITimeSeriesRangeSlider {
  id: any;
  ticks: number;
  checked: string | boolean | undefined;
  timeseries: Array<string>
  onChange?: (newDates: Array<string>) => void;
}

function TimeSeriesRangeSlider(props: ITimeSeriesRangeSlider) {
  const { id, ticks, checked, timeseries, onChange } = props
  const [value, setValue] = useState<number[]>([0, ticks])

  const mapContext = useContext(MapContext) as MapContextType;



  const handleChange = (event: React.SyntheticEvent | Event, value: number | Array<number>) => {
    if (Array.isArray(value)) {
      setValue(value);
      if (value.length === 2) {
        const startIndex = value[0];
        const endIndex = value[1];
        const filteredDates = timeseries.filter((x,i) => { return i >= startIndex && i <= endIndex})
        if (onChange) {
          onChange(filteredDates);
        }
      }
    }
  };

  const handleValueLabel = (x: number) => {
    if (timeseries && timeseries.length > x) {
      return timeseries[x];
    } else {
      return '?'
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(timeseries);
    }
  }, []);

  return (
    <Slider
      size="medium"
      // aria-label="date range"
      value={value}
      valueLabelDisplay="on"
      valueLabelFormat={handleValueLabel}
      step={1}
      marks
      min={0}
      max={ticks}
      onChange={handleChange}
      disabled={checked ? false : true}
      sx={{ mt: 4, mx: 5, width: "80%" }}
    />
  )
}

export default TimeSeriesRangeSlider