import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Splash from "./Splash";

import './styles/layout.css';
import './styles/map.css';
import './styles/react-datetime.css';
import './styles/splash-screen.css';

import i18n from "./i18n";

import * as dotenv from 'dotenv';

dotenv.config();

ReactDOM.render(<Splash />, document.getElementById("root"));

i18n.on('loaded', function(loaded) {
	setTimeout(() => {
		ReactDOM.render(<App/>, document.getElementById("root"));
	}, 500);
})
