import { IModel } from "@/@types/models/model";

import { coreFields } from "./helpers/coreFields";

const AreasOfInterest: IModel = {
  title: "Image Logs",
  apiPath: "image-logs",
  forms : {
    "default": {
      fields: ["id"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "created_on", "image_state_ttoken", "remarks"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.created_on,
    {
      title: "image_state_id",
      source: "image_state_id",
      ttoken: "image_log.image_state",
      type: "wholenum",
      filter: true,
      sort: false
    },
    {
      title: "image_state_ttoken",
      source: "image_state_ttoken",
      ttoken: "image_log.image_state",
      type: "text",
      translate: true,
      filter: true,
      sort: false
    },
    {
      title: "remarks",
      source: "remarks",
      ttoken: "image_log.remarks",
      type: "text",
      filter: true,
      sort: false
    }
  ]
}

export default AreasOfInterest;
