import * as React from "react";

import AppPage from "UI/AppPage/AppPage";
import ImagesTable from "./ImagesTable";

import Box from '@mui/material/Box';

function ImagesPage() {
  return (
    <AppPage>
      <Box m={2}>
        <ImagesTable />
      </Box>
    </AppPage>
  );
}

export default ImagesPage;
