import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// MUI
import { Button, Divider, Typography} from '@mui/material'
import MapIcon from '@mui/icons-material/Map';

//Images
import CHL from "@/images/map_legend/Sibenik_CHL_legend.png";
import TSM from "@/images/map_legend/Sibenik_TSM_legend.png";

// Custom components
import { LayerTree } from '../Map/Controls'

function NewLayersPane(props: any) {
    const { layersCollection, onLayerVisibilityChange } = props
    const [ legendVisibility, setLegendVisibility ] = useState({ chl: true, tsm: true })

    const { t } = useTranslation();
    const navigate = useNavigate();

    function handleLegendVisibility(visibleLayersString: string) {
        let chl = false
        let tsm = false

        if (visibleLayersString.indexOf("chl") != -1) {
            chl = true
        }

        if (visibleLayersString.indexOf("tsm") != -1) {
            tsm = true
        }

        setLegendVisibility({chl: chl, tsm: tsm})
    }

    return (
        <>
            <LayerTree
                ready={layersCollection ? true : false}
                layersCollection={layersCollection}
                onLayerVisibilityChange={onLayerVisibilityChange}
                handleLegendVisibility={handleLegendVisibility}
            />
            <Button variant='contained' startIcon={<MapIcon />} onClick={() => { navigate("/datasets")}} >
                {t("map:buttons.datasets")}
            </Button>
            <Divider sx={{ mb: 2, mt: 1 }} />
            <Typography variant="h6" paragraph={true}>
                {t("map:layers_data.legend")}
            </Typography>
            { legendVisibility.chl ? <img src={CHL} width={379} height={178} /> : null }
            { legendVisibility.tsm ? <img src={TSM} width={379} height={178} /> : null }
        </>
    )
}

export default NewLayersPane