import { MenuType } from "@/@types/common";
import { Permissions } from "@/lib/permissions";

const menuRoutes: MenuType[] = [
	{ path: "/", name: "Home", ttoken: "menu.home", icon: "home" },
	// { path: "/map", name: "map", ttoken: "menu.map", icon: "map" },
	{ path: "/users", name: "users", ttoken: "menu.users", icon: "group", needPermission: [Permissions.Administration] },
	{ path: "/datasets", name: "images", ttoken: "menu.datasets", icon: "map" }
	// { path: "/test", name: "test", ttoken: "menu.test", icon: "category" },
	// { path: "/graphs", name: "graphs", ttoken: "menu.graphs", icon: "equalizer" }
];

export default menuRoutes;
