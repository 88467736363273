import * as React from "react";
import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers
import OlLayerVector from "ol/layer/Vector";

//Custom components
import MapContext from "@/components/Map/MapContext";
import GroupLayerContext from "./GroupLayer/GroupLayerContext";
import { findLayer, getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { GroupLayerContextType } from "@/@types/components/Map/Layers/GroupLayer";
import { IVectorLayer } from "@/@types/components/Map/Layers";

const VectorLayer: FunctionComponent<IVectorLayer> = (props) => {
  const mapContext = useContext(MapContext) as  MapContextType;
  const parentLayerContext = useContext(GroupLayerContext) as GroupLayerContextType;

  let layer = undefined;

  const options = {
    className: undefined,
    opacity: undefined,
    visible: undefined,
    extent: undefined,
    zIndex: undefined,
    minResolution: undefined,
    maxResolution: undefined,
    renderOrder: undefined,
    rednerBuffer: undefined,
    source: undefined,
    map: undefined,
    declutter: undefined,
    style: undefined,
    updateWhileAnimating: undefined,
    updateWhileInteracting: undefined
  };

  const idKey = "id";
  const titleKey = "title";

  const events = {
    'change': undefined,
    'change:extent': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:source': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    'error': undefined,
    'postrender': undefined,
    'prerender': undefined,
    'propertychange': undefined
  };

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = getDefinedOptions(allOptions);

    layer = new OlLayerVector(definedOptions);
    if (props.id) {
      layer.set(idKey, props.id);
    }
    if (props.title) {
      layer.set(titleKey, props.title);
    }
    if(props.zIndex){
      layer.setZIndex(props.zIndex);
    }

    if (parentLayerContext && parentLayerContext.exists && parentLayerContext.childLayers) {
      // console.log('*** parent layer')
      parentLayerContext.childLayers.push(layer);

    } else if (mapContext.map) {
      const mapLayers = mapContext.map.getLayers();
      const mapLayer = mapLayers.getArray().find(x => x instanceof OlLayerVector && x.get(idKey) === props.id);

      if (mapLayer) {
        // console.log('--remove mapLayer', mapLayer);
        mapContext.map.removeLayer(mapLayer);
      }
      // console.log('++add Layer', layer);
      mapContext.map.addLayer(layer);
    } else {
      // console.log('push init layer', layer);
      mapContext.initOptions.layers.push(layer)
    }

    let olEvents = getEvents(events, props);
    for(let eventName in olEvents) {
      //@ts-ignore TODO: TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      layer.on(eventName, olEvents[eventName]);
    }

    layer.changed();

    return () => {
      if (mapContext.map) {
        const mapLayer = findLayer(mapContext.map, props.id);
        if (mapLayer) {
          // console.log('unmounting TileLayer, removing mapLayer', props.id, mapLayer)
          mapContext.map.removeLayer(mapLayer);
        }
      }
    };

    //console.log('layer Vector mounted', layer);
  }, []);

  useEffect(() => {
    if (mapContext.map && props.opacity !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setOpacity(props.opacity);
    }
  },[props.opacity])

  useEffect(() => {
    if (mapContext.map) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setVisible(props.visible ? true: false);
    }
  }, [props.visible]);

  useEffect(() => {
    if (mapContext.map) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setExtent(props.extent);
    }
  },[props.extent])

  useEffect(() => {
    if (mapContext.map && props.zIndex !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setZIndex(props.zIndex);
    }
  }, [props.zIndex]);

  useEffect(() => {
    if (mapContext.map && props.minResolution !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setMinResolution(props.minResolution);
    }
  }, [props.minResolution]);

  useEffect(() => {
    if (mapContext.map && props.maxResolution !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setMaxResolution(props.maxResolution);
    }
  }, [props.maxResolution]);

  useEffect(() => {
    if (mapContext.map && props.source !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      if (mapLayer && mapLayer instanceof OlLayerVector) {
        mapLayer.setSource(props.source);
      }
    }
  },[props.source])

  useEffect(() => {
    if (mapContext.map && props.map) {
      const mapLayer = findLayer(mapContext.map, props.id);
      if (mapLayer && mapLayer instanceof OlLayerVector) {
        mapLayer.setMap(props.map);
      }
    }
  }, [props.map])

  useEffect(() => {
    if (mapContext.map && props.style) {
      const mapLayer = findLayer(mapContext.map, props.id);
      if (mapLayer && mapLayer instanceof OlLayerVector) {
        mapLayer?.setStyle(props.style);
      }
    }
  }, [props.style])

  // props.className,
  // props.renderOrder,
  // props.renderBuffer,
  // props.declutter,
  // props.updateWhileAnimating,
  // props.updateWhileInteracting

  return null;
};

export default VectorLayer;

